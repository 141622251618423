import React, { FC } from "react";
import { Box } from "darker-matter";
import { Modal } from "AtlasChat/components/Modal/Modal.tsx";

interface IViewData {
  header: string;
  content: string;
  buttonText: string;
  buttonAppearance: "primary" | "destructive";
}

const getViewData = (isStart: boolean): IViewData => {
  const header = isStart ? "Start session?" : "End session?";
  const content = isStart
    ? "You are about to start a new session. Please confirm to proceed."
    : "You are about to end this session. Please confirm to proceed.";
  const buttonText = isStart ? "Start session" : "End session";
  const buttonAppearance = isStart ? "primary" : "destructive";

  return {
    header,
    content,
    buttonText,
    buttonAppearance
  };
};

interface IConfirmationModalProps {
  isStart: boolean;
  open: boolean;
  onClose: () => void;
  onAction: () => void;
}

const ConfirmationModal: FC<IConfirmationModalProps> = ({
  isStart,
  open,
  onClose,
  onAction
}) => {
  const { header, content, buttonText, buttonAppearance } = getViewData(
    isStart
  );

  return (
    <Modal
      maxWidth="480px"
      showModal={open}
      headerContent={header}
      buttonLabel={buttonText}
      buttonAppearance={buttonAppearance}
      onProceed={onAction}
      onCancel={onClose}
    >
      <Box mb={6}>{content}</Box>
    </Modal>
  );
};

export default ConfirmationModal;
