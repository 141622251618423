/* eslint-disable */
import React from "react";
import styled from "styled-components";
import { IButtonComponentProps, Icon, Text } from "darker-matter";

import { buttonResetStyleProps } from "styles/shared.ts";
import { coreStyleFeatures } from "styles/shared-style-props.ts";
import { IActionItemSharedProps } from "../types.ts";
import { actionStyles } from "../styles.ts";

export type IActionListButton = IButtonComponentProps & IActionItemSharedProps;

const StyledButton = styled("button")<IActionListButton>(
  buttonResetStyleProps,
  actionStyles,
  coreStyleFeatures
);

export const Button: React.FC<IActionListButton> = ({
  ariaLabel,
  ariaDescription,
  destructive = false,
  disabled = false,
  id,
  label,
  icon,
  ...props
}) => {
  return (
    <StyledButton
      {...props}
      id={id}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-describedby={`${id}-desc`}
      $color={destructive ? "error.main" : "ink.main"}
      {...props}
    >
      {icon && (
        <Icon
          verticalAlign="bottom"
          name={icon}
          decorative
          width="16px"
          height="16px"
          flexShrink={0}
          mr={3}
        />
      )}
      <Text
        id={`${id}-desc`}
        aria-description={ariaDescription}
        display="flex"
        textStyle="small"
        flexGrow={1}
        $color="currentColor"
      >
        {label}
      </Text>
    </StyledButton>
  );
};
