/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { setLoading } from "Loading/data/actions";
import useConfig from "config/useConfig";

const LoggedOutPage = ({ setReduxLoading }) => {
  const config = useConfig();
  useEffect(() => {
    setReduxLoading();
  }, [setReduxLoading]);

  const href = `${config?.member_home}`;
  return (
    <div>
      <p>logged out.</p>
      <p>
        Click{" "}
        <a href={href} target="_blank" rel="noreferrer noopener">
          here
        </a>{" "}
        to log back in
      </p>
    </div>
  );
};

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch) => ({
  setReduxLoading: () => dispatch(setLoading(false))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoggedOutPage);
