import React from "react";
import AppointmentHeader from "AppointmentHeader";
import Chat from "Chat";
import Feedback from "AppointmentFeedback";
import styles from "./layoutTherapistChat.module.scss";

// TODO: not in use
const ChatSession = () => (
  <div className={styles.page} data-testid="TherapistChat">
    <AppointmentHeader />
    <div className={styles.main}>
      <Chat v2 />
    </div>
    <Feedback />
  </div>
);
export default ChatSession;
