import css from "@styled-system/css";
import { transparentize, rem } from "polished";

export const baseStyles = css({
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: "modal",
  backgroundColor: transparentize(0.5, "#000000"),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
});

export const modalStyles = css({
  backgroundColor: "neutral.lighter",
  borderRadius: "xl",
  boxShadow: "elevation3",
  mx: 2,
  overflowY: "auto",
  "& header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: rem(64),
    backgroundColor: "neutral.light",
    borderTopLeftRadius: "md",
    borderTopRightRadius: "md",
    p: 2
  },
  "& footer": {
    p: 5
  }
});

export const contentStyles = css({
  minHeight: [null, null, rem(160)],
  px: "5",
  mt: "5"
});
