import React, {
  FC,
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import moment from "moment";
import { Box, Icon } from "darker-matter";
import {
  IMessage,
  IParticipant,
  IRecordingTimelineEvent,
  ITimelineEvent
} from "AtlasChat/types";
import Message from "../Message.tsx";
import AudioRecording from "../AudioRecording.tsx";
import { isSameDayAsPrevious } from "../dateTimeFormatter.ts";
import { defaultId, renderTimestamp } from "../../helpers.tsx";
import {
  StyledTimelineBox,
  Line,
  CollapsibleLabel,
  CollapsibleTitle,
  ExpandButton,
  CollapsibleLabelControls,
  TimeLineBorder,
  Tag,
  IconLabel,
  TimeCalendarIcon
} from "./styles.ts";

interface ICollapsibleTimelineProps {
  sessionId: string;
  messageHistory: ITimelineEvent[];
  prevEvent: ITimelineEvent | null;
  allParticipants: IParticipant[];
  isSessionOwner: boolean;
  lastSession?: boolean;
  intersectingRefs: MutableRefObject<Record<string, HTMLDivElement>>;
  firstTimestampIndex: MutableRefObject<number>;
  isSysAdmin: boolean;
}

const ONE_MINUTE = 60000;

const getTimeDiff = (startTime: string, endTime?: string) => {
  const start = moment(startTime);
  const end = moment(endTime);
  const duration = moment.duration(end.diff(start));
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes()) % 60;
  let time = ``;
  if (hours > 0) {
    time += `${hours}h `;
  }
  time += `${minutes}m`;

  return time;
};

const getMessagesLength = (messages: ITimelineEvent[]) =>
  messages.reduce(
    (acc, event) => {
      if (event.event_type === "Sentence") {
        acc.messageCount += 1;
      }

      if ((event as IMessage)?.attachments?.length) {
        acc.attachmentCount += (event as IMessage).attachments.length;
      }
      if ((event as IRecordingTimelineEvent).size) {
        acc.attachmentCount += 1;
      }

      return acc;
    },
    {
      messageCount: 0,
      attachmentCount: 0
    }
  );

const CollapsibleTimeline: FC<ICollapsibleTimelineProps> = ({
  sessionId,
  messageHistory,
  prevEvent,
  lastSession,
  allParticipants,
  isSessionOwner,
  intersectingRefs,
  firstTimestampIndex,
  isSysAdmin
}) => {
  const [sessionDuration, setSessionDuration] = useState("");
  const [height, setHeight] = useState<number | undefined>();
  const ref = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const lastEvent = messageHistory[messageHistory.length - 1];
  const sessionStarted = messageHistory[0];
  const sessionEnded =
    lastEvent?.event_type === "ScheduledSessionEnded" && lastEvent;
  const [collapsed, setCollapsed] = useState(!!sessionEnded);
  const messageInfo = getMessagesLength(messageHistory);
  const startDate = sessionStarted?.time_utc;
  const endDate = sessionEnded?.time_utc;
  const sameDay = isSameDayAsPrevious(
    sessionStarted.time_utc,
    prevEvent?.time_utc
  );

  useLayoutEffect(() => {
    setHeight(ref.current?.scrollHeight);
  }, [ref, messageHistory, sessionEnded]);

  useEffect(() => {
    let intervalId = null;
    const duration = getTimeDiff(startDate, endDate);
    setSessionDuration(duration);
    if (!endDate) {
      intervalId = setInterval(() => {
        const newDuration = getTimeDiff(startDate, endDate);
        setSessionDuration(newDuration);
      }, ONE_MINUTE);
    }
    return () => intervalId && clearInterval(intervalId);
  }, [startDate]);

  const onClick = () => {
    setCollapsed((prevState) => !prevState);
    setTimeout(() => {
      if (ref.current && lastSession) {
        ref.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }, 300);
  };

  return (
    <>
      {!sameDay &&
        renderTimestamp({
          index: 0,
          event: sessionStarted,
          firstTimestampIndex,
          intersectingRefs
        })}
      <StyledTimelineBox
        $collapsed={collapsed}
        $height={sessionEnded ? height : undefined}
        ref={ref}
      >
        <TimeLineBorder $collapsed={collapsed} />
        <CollapsibleLabel mb={2} flexDirection="column">
          <Box display="flex" alignItems="center" width="100%">
            <Line />
            <CollapsibleTitle textStyle="small" color="ink.dark" px={5}>
              Session start
            </CollapsibleTitle>
            {sessionEnded ? (
              <CollapsibleLabelControls>
                <Line />
                <ExpandButton
                  appearance="link"
                  onClick={onClick}
                  forwardedRef={btnRef}
                  ariaLabel={collapsed ? "View Session" : "Hide"}
                  ariaDescription={collapsed ? "View session" : "Hide Session"}
                  ariaExpanded={!collapsed}
                >
                  {collapsed ? "View Session" : "Hide"}
                </ExpandButton>
                <Line $width="30px" />
              </CollapsibleLabelControls>
            ) : (
              <Line />
            )}
          </Box>
          <Box
            height="28px"
            display={sessionEnded ? "flex" : "none"}
            alignItems="center"
            width="100%"
          >
            <Tag>
              <TimeCalendarIcon />
              <IconLabel textStyle="small" color="ink.light">
                {sessionDuration}
              </IconLabel>
            </Tag>
            <Tag>
              <Icon
                $color="ink.light"
                accessibilityTitle="Messages count"
                height="16px"
                width="16px"
                name="chat-conversation"
              />
              <IconLabel textStyle="small" color="ink.light">
                {messageInfo.messageCount}
              </IconLabel>
            </Tag>
            <Tag>
              <Icon
                $color="ink.light"
                accessibilityTitle="Attachment count"
                height="16px"
                width="16px"
                name="attachment"
              />
              <IconLabel textStyle="small" color="ink.light">
                {messageInfo.attachmentCount}
              </IconLabel>
            </Tag>
          </Box>
        </CollapsibleLabel>
        <div aria-hidden={collapsed} aria-expanded={!collapsed}>
          <>
            {messageHistory?.map((message, i, messages) => {
              let component;
              const prevSession = messages[i - 1];
              const nextSession = messages[i + 1];
              const sameDayInSession = isSameDayAsPrevious(
                message.time_utc,
                i === 0 ? prevEvent?.time_utc : prevSession.time_utc
              );
              const key =
                message.event_guid === defaultId
                  ? `${message.time_utc}-${message.event_type}`
                  : message.event_guid;

              switch (message.event_type) {
                case "AudioRecording":
                  component = (
                    <AudioRecording
                      compositionGuid={
                        (message as IRecordingTimelineEvent).compositionGuid
                      }
                      compositionStatus={
                        (message as IRecordingTimelineEvent).compositionStatus
                      }
                      errorMessage={
                        (message as IRecordingTimelineEvent).errorMessage
                      }
                      disabled={collapsed}
                      eventGuid={message.event_guid}
                      timeUtc={message.time_utc}
                      sessionId={sessionId}
                    />
                  );
                  break;
                case "Sentence":
                case "typing":
                  component = (
                    <Message
                      message={message as IMessage}
                      nextMessage={nextSession as IMessage}
                      sessionId={sessionId}
                      isSessionOwner={isSessionOwner}
                      allParticipants={allParticipants}
                      isSysAdmin={isSysAdmin}
                      disabled={collapsed}
                    />
                  );
                  break;
                default:
                  component = null;
              }

              return (
                <React.Fragment key={key}>
                  {i === 0 && !sameDay
                    ? null
                    : !sameDayInSession &&
                      renderTimestamp({
                        index: i,
                        event: message,
                        firstTimestampIndex,
                        intersectingRefs
                      })}
                  {component}
                </React.Fragment>
              );
            })}
            {!!sessionEnded && (
              <CollapsibleLabel mt={2}>
                <Line />
                <CollapsibleTitle textStyle="small" color="ink.dark" px={5}>
                  Session end
                </CollapsibleTitle>
                <CollapsibleLabelControls>
                  <Line />
                  <ExpandButton
                    appearance="link"
                    onClick={() => {
                      setCollapsed(true);
                      btnRef.current?.focus();
                    }}
                    ariaLabel="Hide"
                    ariaDescription="Hide session"
                    disabled={collapsed}
                  >
                    Hide
                  </ExpandButton>
                  <Line $width="30px" />
                </CollapsibleLabelControls>
              </CollapsibleLabel>
            )}
          </>
        </div>
      </StyledTimelineBox>
    </>
  );
};

function arePropsEqual(
  prevProps: ICollapsibleTimelineProps,
  nextProps: ICollapsibleTimelineProps
) {
  return !nextProps.lastSession;
}

export default React.memo(CollapsibleTimeline, arePropsEqual);
