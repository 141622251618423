import React from "react";
import PropTypes from "prop-types";
import { Button } from "dark-matter";
import ContentEditable from "react-contenteditable";
import { connect } from "react-redux";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";

import { getConnectionStatus } from "store/chatconnection/selectors";
import {
  notifyTyping as notifyTypingAction,
  setCurrentMessage,
  sendMessage as sendMessageAction
} from "store/chatconnection/actions";
import sanitizeMessage from "utils/sanitize-message";
import throttleNotify from "utils/throttleNotify";
import { CONNECTION_STATUS_STRINGS } from "store/chatconnection/consts";
import styles from "./ChatTextbox.module.scss";

class TextBox extends React.Component {
  static propTypes = {
    connectionStatus: PropTypes.string.isRequired,
    notifyTyping: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    setShowEmoji: PropTypes.func.isRequired,
    showEmoji: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      html: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.setMessage = this.setMessage.bind(this);
    this.onKeyPressEnter = this.onKeyPressEnter.bind(this);
  }

  handleChange = (evt) => {
    const { notifyTyping } = this.props;
    this.setMessage(evt.target.value, evt);
    Promise.resolve().then(() => notifyTyping());
  };

  setMessage = (value, evt) => {
    const sanitizedValue = sanitizeMessage(value);
    if (
      evt &&
      evt.nativeEvent &&
      evt.nativeEvent.inputType === "insertFromPaste"
    ) {
      this.setState({ html: sanitizedValue });
    } else {
      this.setState({ html: value });
    }
  };

  onKeyPressEnter = (e) => {
    const { sendMessage, connectionStatus } = this.props;
    const { html } = this.state;
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (
        // eslint-disable-next-line react/destructuring-assignment
        connectionStatus === CONNECTION_STATUS_STRINGS.Connected
      ) {
        sendMessage(html);
        this.setMessage("", e);
      }
    }
  };

  handleSend = (e) => {
    const { sendMessage } = this.props;
    const { html } = this.state;
    sendMessage(html);
    this.setMessage("", e);
  };

  handleSelectEmoji = (emoji) => {
    const { html } = this.state;
    const { setShowEmoji } = this.props;
    this.setMessage(`${html} ${emoji.native}`);
    setShowEmoji(false);
  };

  toggleEmoji = () => {
    const { showEmoji, setShowEmoji } = this.props;
    setShowEmoji(!showEmoji);
  };

  render() {
    const { html } = this.state;
    const { showEmoji, connectionStatus } = this.props;

    return (
      <div className={styles.container}>
        {!html && (
          <span
            className={styles.placeholder}
            aria-hidden="true"
            role="presentation"
            tabIndex="-1"
          >
            Type your message&hellip;
          </span>
        )}
        <div className={styles.inputBar}>
          <ContentEditable
            className={styles.input}
            html={html}
            onBlur={(evt) => {
              if (evt.target.textContent === "") {
                this.setMessage("", evt);
              }
            }}
            onChange={this.handleChange}
            onKeyPress={this.onKeyPressEnter}
          />
          <Button
            appearance="primary"
            solid
            icon="send"
            disabled={connectionStatus !== CONNECTION_STATUS_STRINGS.Connected}
            onClick={this.handleSend}
          >
            Send
          </Button>
        </div>
        {showEmoji && (
          <Picker
            emoji=""
            style={{
              position: "absolute",
              left: "0.8rem",
              bottom: "100%"
            }}
            showPreview={false}
            showSkinTones={false}
            native
            onSelect={this.handleSelectEmoji}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const connectionStatus = getConnectionStatus(state);
  return {
    connectionStatus
  };
}

const mapDispatchToProps = (dispatch) => ({
  editMessage: (value) => {
    dispatch(setCurrentMessage(value));
  },
  sendMessage: (v) => {
    dispatch(sendMessageAction(v));
  },
  notifyTyping: throttleNotify(() => {
    dispatch(notifyTypingAction());
  }, 5000)
});

export default connect(mapStateToProps, mapDispatchToProps)(TextBox);
