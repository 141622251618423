import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { setLoading } from "Loading/data/actions";
import { USER_FOUND } from "Identity/actions";
import { useAuth0 } from "@auth0/auth0-react";

const DEFAULT_CHECK_SESSION_MS = 1000 * 60 * 15; // 15 minutes

// Retrieve access token every 15 minutes
const useRefreshToken = (checkSession, setReduxUser) => {
  const TIMEOUT_MS = checkSession || DEFAULT_CHECK_SESSION_MS;
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const userId = user?.["https://thinkwell.co.uk/ieso_user_id"];

  useEffect(() => {
    let timerId;
    if (isAuthenticated) {
      const getToken = async () => {
        try {
          const token = await getAccessTokenSilently({
            cacheMode: "off"
          });
          setReduxUser({ user, token });
          localStorage.setItem("retry", "0");
        } catch (err) {
          const timeoutError = err.error === "timeout";
          if (timeoutError) {
            const retryCount = +(localStorage.getItem("retry") || 0);
            localStorage.setItem("retry", `${retryCount + 1}`);
            if (retryCount < 3) {
              setTimeout(getToken, 5000);
            }
          } else {
            localStorage.removeItem("retry");
          }
        }
      };
      timerId = setInterval(getToken, TIMEOUT_MS);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [TIMEOUT_MS, getAccessTokenSilently, isAuthenticated, userId]);
};

const Identity = (props) => {
  const { children, setReduxLoading, setReduxUser, checkSession } = props;
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  useRefreshToken(checkSession, setReduxUser);

  useEffect(() => {
    const setUser = async () => {
      try {
        const token = await getAccessTokenSilently();
        setReduxUser({ user, token });
        setReduxLoading(false);
      } catch {
        setReduxLoading(false);
      }
    };
    if (user && isAuthenticated) {
      setUser();
    }
  }, [user, isAuthenticated]);

  if (!user) {
    return null;
  }

  return children;
};

const mapDispatchToProps = (dispatch) => ({
  setReduxLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setReduxUser: ({ user, token }) => {
    return dispatch({
      type: USER_FOUND,
      payload: {
        access_token: token,
        // I think this can be id token but need to dig
        id_token: token,
        name: user.name
      }
    });
  }
});

export default withRouter(connect(null, mapDispatchToProps)(Identity));
