/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { rem } from "polished";
import styled from "styled-components";
import { IBoxComponentProps, Box, ButtonGroup, Text } from "darker-matter";
import { coreStyleFeatures } from "styles/shared-style-props.ts";
import { IconButton } from "../IconButton/iconButton.tsx";
import { Button } from "../Button/button.tsx";
import { baseStyles, modalStyles, contentStyles } from "./styles.ts";

export type IActionProps = {
  /**
   * On action button clicked callback
   */
  onAction: () => void;
  /**
   * Primary action button text
   */
  action: string;
  /** Visually hidden text for screen readers */
  ariaLabel?: string;
  /**
   *  Button is in a loading state, shows a spinner icon, hides text and disables button
   */
  loading?: boolean;
};
export interface IModalProps extends IBoxComponentProps {
  /**
   * Unique modal identifier
   */
  id?: string;
  /**
   * Header content
   */
  header?: React.ReactNode | string;
  /**
   * Custom Footer content
   */
  footer?: React.ReactNode | string;
  /**
   * On close button clicked callback
   */
  onClose?: () => void;
  /**
   * set content "as" prop for styled components.
   */
  contentAs?: React.ElementType | keyof JSX.IntrinsicElements;
  /**
   * Action button props if using default footer.
   */
  actionButton?: IActionProps;
  /**
   * Hides close button and Cancel button if set to false. Default value is true.
   */
  dismissible?: boolean;
  ariaLabel?: string;
  minWidth?: string;
  maxWidth?: string;
  children?: React.ReactNode;
  showModal?: boolean;
  onProceed?: () => void;
  onCancel?: () => void;
}

const StyledDismiss = styled(IconButton)({
  flex: 1,
  opacity: 0.4,
  color: "ink.main",
  p: 0,
  minHeight: rem(24),
  minWidth: rem(24),
  height: 0,
  "&:hover": {
    opacity: 1,
    bg: "transparent"
  },
  "&:focus": {
    bg: "transparent",
    opacity: 1,
    boxShadow: `0 0 0 2px transparent, 0 0 0 2px #017A67`
  }
});
const StyledBackground = styled.div(baseStyles);
const StyledModal = styled(Box)(modalStyles);
const StyledContent = styled(Box)(coreStyleFeatures, contentStyles);

export const Modal: React.FC<any> = ({
  children,
  header,
  footer,
  minWidth = "280px",
  maxWidth = "480px",
  onClose,
  contentAs,
  actionButton,
  dismissible = true,
  ...props
}) => {
  return (
    <StyledBackground {...props}>
      <StyledModal minWidth={minWidth} maxWidth={maxWidth}>
        {header && (
          <header>
            {header ? (
              <Text textStyle="medium" fontWeight="bold" as="h3" ml={3}>
                {header}
              </Text>
            ) : (
              header
            )}
            {dismissible && (
              <Box>
                <StyledDismiss
                  icon="cross"
                  ariaLabel="Close modal"
                  appearance="tertiary"
                  onClick={onClose}
                />
              </Box>
            )}
          </header>
        )}
        <StyledContent as={contentAs}>{children}</StyledContent>
        <footer>
          {footer ?? (
            <>
              {actionButton ? (
                <ButtonGroup
                  justifyContent="flex-end"
                  ariaLabel="action buttons"
                  flexDirection={{ _: "column-reverse", sm: "row" }}
                >
                  <Button appearance="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    appearance="primary"
                    onClick={actionButton.onAction}
                    ariaLabel={actionButton.ariaLabel}
                    loading={actionButton.loading}
                  >
                    {actionButton.action}
                  </Button>
                </ButtonGroup>
              ) : (
                <></>
              )}
            </>
          )}
        </footer>
      </StyledModal>
    </StyledBackground>
  );
};

Modal.displayName = "Modal";

export default Modal;
