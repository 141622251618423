import React, { useCallback } from "react";
import { Picker } from "emoji-mart";
import { BaseEmoji } from "emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index";
import { usePopover } from "darker-matter";
import { ToolbarButton } from "./styles.ts";

export type Emoji = BaseEmoji;

const EmojiPicker: React.FC<{ onSelect(emoji: Emoji): void }> = ({
  onSelect
}) => {
  const { Popover, hide, show } = usePopover();

  const trigger = (
    <ToolbarButton
      id="emojiButton"
      role="button"
      appearance="tertiary"
      icon="emoji-happy"
      aria-label="Emoji's"
      ariaDescription="Select an emoji"
      aria-haspopup="true"
      onClick={show}
    />
  );

  const handleSelect = useCallback(
    (emoji: Emoji) => {
      hide();
      onSelect?.(emoji);
    },
    [hide, onSelect]
  );

  // The IconButton is NOT the trigger otherwise it will "eat" the first click
  // and you will need two click to open the popover. We should really have a DropdownButton
  // of some sort.
  return (
    <>
      {trigger}
      <Popover trigger={<div />} id="emoji" placement="auto">
        <Picker
          emoji=""
          showPreview={false}
          showSkinTones={false}
          native
          onSelect={handleSelect}
        />
      </Popover>
    </>
  );
};

export default EmojiPicker;
