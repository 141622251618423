import React, { MutableRefObject } from "react";
import {
  IMessage,
  IParticipant,
  IRecordingTimelineEvent,
  ITimelineEvent
} from "AtlasChat/types";
import Message from "./components/Message.tsx";
import AudioRecording from "./components/AudioRecording.tsx";
import { isSameDayAsPrevious } from "./components/dateTimeFormatter.ts";
import Timestamp from "./components/Timestamp.tsx";
import { StyledTimestamp } from "./styles.ts";

interface IEvents {
  id: string;
  index: number;
  isSession: boolean;
  transitStatus: "maybe" | "failed";
}
interface IMappedEvent {
  event: ITimelineEvent;
  isSession: boolean;
  sessionsLength: number;
}

const mappedEvents: IEvents[] = [];

const addMappedEvent = (mappedEvent: IMappedEvent) => {
  if (mappedEvent.event.event_type !== "Sentence") return;

  const ev = mappedEvent.event as IMessage;
  if (ev.transit_status === "maybe") {
    mappedEvents.push({
      id: ev.event_guid,
      index: mappedEvent.sessionsLength,
      isSession: mappedEvent.isSession,
      transitStatus: ev.transit_status
    });
  }
};

export const defaultId = "00000000-0000-0000-0000-000000000000";
export type TSessions = (ITimelineEvent | ITimelineEvent[])[];
export interface ITimelineData {
  prevSessions: TSessions;
  newEvents: ITimelineEvent[];
  messageHistory: ITimelineEvent[];
}

const isDuplicate = (eventGuid: string, arr: TSessions) => {
  const index = arr.findIndex(
    (i) => !Array.isArray(i) && (i as ITimelineEvent).event_guid === eventGuid
  );

  return index > -1;
};

const addEventToCurrentSession = (
  session: TSessions,
  event: ITimelineEvent,
  sessionsLength: number
) => {
  const eventGuid = event.event_guid;
  if (eventGuid === defaultId || !isDuplicate(eventGuid, session)) {
    session.push(event);
    addMappedEvent({
      event,
      isSession: true,
      sessionsLength
    });
  }
};

const addEventToCurrentSessions = (
  sessions: TSessions,
  event: ITimelineEvent
) => {
  const eventGuid = event.event_guid;
  if (eventGuid === defaultId || !isDuplicate(eventGuid, sessions)) {
    sessions.push(event);
    addMappedEvent({
      event,
      isSession: false,
      sessionsLength: sessions.length - 1
    });
  }
};

const getFilteredMessages = (messages: ITimelineEvent[]) => {
  return messages.filter((i) => (i as IMessage).transit_status !== "failed");
};

export const getNewEvent = (
  messageHistory: ITimelineEvent[],
  prevMsgHistory: ITimelineEvent[]
) => {
  const filteredMessageHistory = getFilteredMessages(messageHistory);
  const filteredPrevMsgHistory = getFilteredMessages(prevMsgHistory);

  return filteredMessageHistory.slice(filteredPrevMsgHistory.length);
};

export const extractSessions = (
  prevSessions: TSessions = [],
  newEvents: ITimelineEvent[] = [],
  messageHistory: ITimelineEvent[]
) => {
  const sessions = prevSessions;

  const prevSession = prevSessions[prevSessions.length - 1];
  let session = [];

  if (
    prevSessions.length > 0 &&
    Array.isArray(prevSession) &&
    prevSession[prevSession.length - 1].event_type !== "ScheduledSessionEnded"
  ) {
    session = sessions.pop() as ITimelineEvent[];
  }

  for (let i = 0; i < newEvents.length; i += 1) {
    const event = newEvents[i];

    if (event.event_type === "ScheduledSessionStarted") {
      session = [];
      session.push(event);
    } else if (event.event_type === "ScheduledSessionEnded") {
      session.push(event);
      sessions.push(session);
      session = [];
    } else if (session.length > 0) {
      addEventToCurrentSession(session, event, sessions.length);
    } else {
      addEventToCurrentSessions(sessions, event);
    }
  }

  if (session.length > 0) {
    sessions.push(session);
    session = [];
  }

  mappedEvents.forEach((item, index, object) => {
    const messageFromHistory = messageHistory.find(
      (i) => i.event_guid === item.id
    );
    const transitStatus = (messageFromHistory as IMessage)?.transit_status;

    if (transitStatus === "failed") {
      if (item.isSession) {
        const messageIndex = (sessions[
          item.index
        ] as ITimelineEvent[]).findIndex((i) => i.event_guid === item.id);
        sessions[item.index][messageIndex] = messageFromHistory;
      } else {
        sessions[item.index] = messageFromHistory;
      }
    }
    if (transitStatus === "failed" || transitStatus === "sent") {
      object.splice(index, 1);
    }
  });

  return sessions;
};

interface IRenderTimestamp {
  index: number;
  event: ITimelineEvent | IMessage;
  firstTimestampIndex: MutableRefObject<number>;
  intersectingRefs: MutableRefObject<Record<string, HTMLDivElement>>;
}

export const renderTimestamp = ({
  firstTimestampIndex,
  index,
  intersectingRefs,
  event
}: IRenderTimestamp) => {
  if (index < firstTimestampIndex.current) {
    firstTimestampIndex.current = index;
  }

  return (
    <StyledTimestamp
      id="date-chip"
      key={event.time_utc}
      $firstElement={firstTimestampIndex.current === index}
      ref={(el) => {
        if (el) {
          intersectingRefs.current[event.time_utc] = el;
        }
      }}
    >
      <Timestamp timeUtc={event.time_utc} />
    </StyledTimestamp>
  );
};

interface IRenderMessage extends IRenderTimestamp {
  sessionId: string;
  sessions: TSessions;
  isSessionOwner: boolean;
  allParticipants: IParticipant[];
  isSysAdmin: boolean;
}

export const renderMessage = ({
  firstTimestampIndex,
  index,
  intersectingRefs,
  event,
  sessions,
  sessionId,
  isSessionOwner,
  allParticipants,
  isSysAdmin
}: IRenderMessage) => {
  const prevElement = sessions[index - 1];
  const nextElement = sessions[index + 1];
  const isPrevMessage = prevElement && !Array.isArray(prevElement);
  const isNextMessage = nextElement && !Array.isArray(nextElement);
  const key =
    event.event_guid === defaultId
      ? `${event.time_utc}-${event.event_type}`
      : event.event_guid;

  let prevTime = null;
  let component;

  if (prevElement) {
    prevTime = isPrevMessage
      ? (prevElement as ITimelineEvent)?.time_utc
      : prevElement[(prevElement as ITimelineEvent[]).length - 1]?.time_utc;
  }

  switch (event.event_type) {
    case "AudioRecording":
      component = (
        <AudioRecording
          compositionGuid={(event as IRecordingTimelineEvent).compositionGuid}
          compositionStatus={
            (event as IRecordingTimelineEvent).compositionStatus
          }
          errorMessage={(event as IRecordingTimelineEvent).errorMessage}
          eventGuid={event.event_guid}
          timeUtc={event.time_utc}
          sessionId={sessionId}
        />
      );
      break;
    case "Sentence":
    case "typing":
      component = (
        <Message
          message={event as IMessage}
          nextMessage={isNextMessage ? (nextElement as IMessage) : null}
          isSessionOwner={isSessionOwner}
          allParticipants={allParticipants}
          sessionId={sessionId}
          isSysAdmin={isSysAdmin}
        />
      );
      break;
    default:
      component = null;
  }

  return (
    <React.Fragment key={key}>
      {!isSameDayAsPrevious(event.time_utc, prevTime) &&
        renderTimestamp({
          firstTimestampIndex,
          index,
          intersectingRefs,
          event
        })}
      {component}
    </React.Fragment>
  );
};

interface IFormMessagesInSession {
  session: ITimelineEvent[];
  typingMessage: ITimelineEvent | null;
  isLast: boolean;
  isArray: boolean;
}

export const formMessagesInSession = ({
  session,
  typingMessage,
  isLast,
  isArray
}: IFormMessagesInSession) => {
  const lastEvent = session[session.length - 1];
  const sessionEnded =
    lastEvent?.event_type === "ScheduledSessionEnded" && lastEvent;
  if (isLast && typingMessage && isArray && !sessionEnded) {
    return [...session, typingMessage];
  }
  return session;
};
