import css from "@styled-system/css";
import { rem } from "polished";

// line height is a specific value rather than a value from the theme object to compensate for borders in the total height.
export const formInputStyleProps = css({
  bg: "white",
  fontSize: 3,
  lineHeight: 1.375,
  border: "1px solid",
  borderColor: "border.rest",
  color: "ink.dark",
  py: 2,
  px: 3,
  borderRadius: "sm",
  width: "100%",
  "&:focus": {
    borderColor: "border.focus",
    boxShadow: "focus",
    outline: "none"
  },
  "&::placeholder": {
    color: "ink.light"
  },
  "&::-webkit-datetime-edit, &::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator, &::-webkit-clear-button": {
    lineHeight: "1"
  },
  "&:disabled": {
    bg: "neutral.light",
    borderColor: "border.disabled",
    color: "ink.light"
  },
  "&[readonly]": {
    bg: "neutral.light",
    borderColor: "border.readonly"
  }
});

export const buttonResetStyleProps = css({
  bg: "transparent",
  border: "none",
  p: 0,
  m: 0,
  fontSize: "inherit",
  fontFamily: "body",
  lineHeight: "inherit",
  textAlign: "left",
  "&:focus": {
    outline: "none",
    boxShadow: "focusRing"
  }
});

export const linkResetStyleProps = css({
  textDecoration: "none",
  color: "inherit",
  "&:hover": {
    textDecoration: "none"
  }
});

// Utility styles for when rich text content is being rendered by content editable divs (eg. compose message) or by speech bubbles.
// Typically this helps solve formatting issues when therpists copy and paste formatted text into chat messages.

export const richTextContentStyleProps = css({
  "h1, h2, h3, h4, h5, h6": {
    fontSize: "inherit",
    fontWeight: "fontWeights.bold",
    color: "inherit",
    lineHeight: "inherit",
    mt: 0,
    mb: 2
  },
  p: {
    mb: 2
  },
  "ul, ol": {
    mx: 2,
    mr: 0,
    ml: 3
  },
  ul: {
    listStyle: "disc"
  },
  ol: {
    listStyle: "decimal"
  },
  "a,a:visited": {
    color: "inherit"
  }
});

export const focusOutlineStyleProps = css({
  outline: "1px solid",
  outlineColor: "secondary.main"
});

export const scrollbarStyles = css({
  "&::-webkit-scrollbar": {
    bg: "neutral.lighter",
    boxShadow: "inset 2px 0 6px rgba(0,0,0,.05)",
    width: rem(8)
  },
  "::-webkit-scrollbar-thumb": {
    borderRadius: "999em",
    bg: "neutral.dark"
  }
});
