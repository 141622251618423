/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";

import configureStore from "store/configureStore.ts";
import { Page404, LoggedOut } from "screens";
import Appointment from "Appointment";
import Identity from "Identity";
import { Telemetry, useTelemetry } from "ieso-telemetry";
import { Banner } from "dark-matter";
import { Router, Route, Switch } from "react-router-dom";
import { COOKIE_KEYS, createCookie } from "utils/cookies";
import Loading from "Loading/Loading";
import history from "./utils/history";
import {
  AppointmentDetails,
  useCreateAppointmentDetailsContext
} from "./AppointmentDetailsProvider";

import { AuthGuard } from "./AuthGuard.tsx";
import AtlasChat from "./AtlasChat";
import { LOCAL_STORAGE_KEYS } from "./utils/localStorage";
import { getTherapyRoomId } from "./utils/common";
import { useWindowLoad } from "./hooks/useWindowLoad";
import { useUserId } from "./hooks/useUserId.ts";
import { TELEMETRY_KEYS } from "./config/telemetry.ts";
import "./App.css";
import "darker-matter/build/assets/fonts/webfonts.css";

const App = ({ config }) => {
  const isDevEnv = process.env.NODE_ENV === "development";
  const telemetry = useTelemetry();
  const userId = useUserId();
  const therapyRoomId = getTherapyRoomId();

  const store = configureStore({ telemetry, therapyRoomId });
  if (isDevEnv) {
    global.store = store;
  }

  useEffect(() => {
    const handleBeforeUnload = () => {
      telemetry.trackEvent(TELEMETRY_KEYS.APP_CLOSED, {
        userId,
        therapyRoomId
      });
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    if (userId && therapyRoomId) {
      telemetry.trackEvent(TELEMETRY_KEYS.APP_LOADED, {
        userId,
        therapyRoomId
      });
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [telemetry, userId, therapyRoomId]);

  return (
    <Provider store={store}>
      <>
        <Loading message="Loading &hellip;" fullscreen />
        <Switch>
          <Route path="/callback">{null}</Route>
          <Route path="/logged-out" exact component={LoggedOut} />
          <Identity checkSession={config.check_session_ms} config={config}>
            <Switch>
              <Route exact path="/therapy/:guid" component={AtlasChat} />
              <Route exact path="/Appointment/:guid" component={Appointment} />
              <Route exact path="/" component={RedirectToRoom} />
            </Switch>
          </Identity>
          <Route component={Page404} />
        </Switch>
      </>
    </Provider>
  );
};

const ErrorBoundary = () => (
  <Banner appearance="critical">
    Something went wrong. Please try again later.
  </Banner>
);

const RedirectToRoom = () => {
  const location = window.location.pathname;
  const storedUrl = localStorage.getItem(
    LOCAL_STORAGE_KEYS.postLogoutRedirectUrl
  );

  if (location === "/" && storedUrl) {
    const url = new URL(storedUrl);
    history.push(url.pathname);
  }
  return null;
};

const TelemetryAppWrapper = ({ config }) => {
  const { telemetry } = config.services;
  const { appInsights } = telemetry;
  const application = {
    name: appInsights.commonDimensions.AppName,
    version: appInsights.commonDimensions.AppVersion
  };
  const telemetryConfig = { appInsights };
  const appointmentDetailsContext = useCreateAppointmentDetailsContext();
  const searchParams = new URLSearchParams(window.location);
  useWindowLoad();

  return (
    <Router history={history}>
      <Telemetry
        config={telemetryConfig}
        fallbackComponent={ErrorBoundary}
        application={application}
      >
        <Auth0Provider
          domain={config.authentication.domain}
          clientId={config.authentication.id}
          onRedirectCallback={() => {
            createCookie(COOKIE_KEYS.iesoCookieTimeout, Date.now());
          }}
          authorizationParams={{
            redirect_uri: window.location.origin,
            ...(searchParams?.get("message")?.length && {
              message: searchParams.get("message")
            }),
            ...(searchParams?.get("success")?.length && {
              success: searchParams.get("success")
            }),
            ...(searchParams?.get("status")?.length && {
              status: searchParams.get("status")
            }),
            ...(searchParams?.get("error")?.length && {
              error: searchParams.get("error")
            }),
            ...(searchParams?.get("error_description")?.length && {
              error_description: searchParams.get("error_description")
            })
          }}
        >
          <AuthGuard sessionTimeout={config.session_timeout_ms}>
            <AppointmentDetails.Provider value={appointmentDetailsContext}>
              <App config={config} />
            </AppointmentDetails.Provider>
          </AuthGuard>
        </Auth0Provider>
      </Telemetry>
    </Router>
  );
};

export default TelemetryAppWrapper;
