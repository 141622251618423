/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Chat } from "screens";
import PropTypes from "prop-types";
import { useAppointmentDetailsContext } from "../AppointmentDetailsProvider";

function Appointment(props) {
  const { myId, setAppointment, sessionNotFound } = props;
  const { guid: appointmentId } = useParams();
  const history = useHistory();
  const appointmentDetailsContext = useAppointmentDetailsContext();

  useEffect(() => {
    if (myId) {
      setAppointment(appointmentId);
      if (appointmentDetailsContext?.setAppointmentId) {
        appointmentDetailsContext.setAppointmentId(appointmentId);
      }
    }
  }, [myId, setAppointment, appointmentId]);

  useEffect(() => {
    if (sessionNotFound) {
      history.push("/404");
    }
  }, [sessionNotFound, history]);
  return <Chat {...props} />;
}

Appointment.propTypes = {
  myId: PropTypes.string,
  setAppointment: PropTypes.any,
  sessionNotFound: PropTypes.bool
};

export default Appointment;
