import React, { FC, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { ChatHeader, Layout, ChatTextBox } from "AtlasChat/components";
import ChatContainer from "AtlasChat/components/ChatContainer/ChatContainer";
import { SessionAvailability } from "AtlasChat/types";
import RestrictedChatView from "../RestrictedChatView/index.ts";
import { StyledTimelineBox } from "./styles.ts";

interface IChatProps {
  myId: string;
  sessionAvailability: SessionAvailability;
  hasTherapistJoined: boolean;
  setReferralID: (referralId: string) => void;
}

const Chat: FC<IChatProps> = ({
  myId,
  sessionAvailability,
  hasTherapistJoined,
  setReferralID
}) => {
  const { guid: chatId } = useParams();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (myId) {
      setReferralID(chatId);
    }
  }, [myId, setReferralID, chatId]);

  if (sessionAvailability !== SessionAvailability.Available) {
    return <RestrictedChatView />;
  }

  return (
    <Layout>
      <ChatHeader />
      <StyledTimelineBox ref={containerRef}>
        <ChatContainer containerRef={containerRef} />
      </StyledTimelineBox>
      {hasTherapistJoined && <ChatTextBox />}
    </Layout>
  );
};

export default Chat;
