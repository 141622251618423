import React from "react";
import PropTypes from "prop-types";

import ChatSessionFinished from "Chat/ChatSessionFinished";
import ChatHistoryRow from "./ChatHistoryRow";
import styles from "./chat.module.scss";

class ChatHistory extends React.PureComponent {
  static propTypes = {
    docked: PropTypes.bool,
    hasReceivedMessageHistory: PropTypes.bool,
    messageHistory: PropTypes.arrayOf(PropTypes.shape({})),
    registerScroll: PropTypes.func.isRequired,
    setDocked: PropTypes.func.isRequired,
    sessionStatus: PropTypes.string.isRequired
  };

  static defaultProps = {
    docked: false,
    hasReceivedMessageHistory: false,
    messageHistory: []
  };

  constructor(props) {
    super(props);

    this.checkIsDocked = this.checkIsDocked.bind(this);

    this.state = {
      historyRef: React.createRef()
    };

    const { registerScroll } = this.props;
    registerScroll(this);
  }

  componentDidMount() {
    this.scrollToBottom();
    this.checkIsDocked();
  }

  componentDidUpdate() {
    const { docked, sessionStatus } = this.props;

    if (docked || sessionStatus === "finished_redirect_patient") {
      this.scrollToBottom();
    }
    this.checkIsDocked();
  }

  checkIsDocked() {
    const { docked, setDocked } = this.props;
    const { historyRef } = this.state;
    const refCurrent = historyRef && historyRef.current;

    if (!refCurrent) return;

    const { clientHeight, scrollHeight, scrollTop } = refCurrent;

    if (clientHeight !== null && scrollHeight !== null && scrollTop !== null) {
      const isDocked = // less than 10% above the bottom of the scrollable window.
        (scrollHeight - scrollTop - clientHeight) / clientHeight < 0.1;

      if (docked !== isDocked) {
        setDocked(isDocked);
      }
    }
  }

  scrollToBottom() {
    clearTimeout(this.scrollTopTaskRef);

    // Schedule scrollTop in next task because of height recalc;
    this.scrollTopTaskRef = setTimeout(() => {
      const { historyRef } = this.state;
      const refCurrent = historyRef && historyRef.current;
      if (!refCurrent) return;

      const { clientHeight, scrollHeight } = refCurrent;

      if (historyRef && historyRef.current) {
        const historyRefCurrent = historyRef.current;
        historyRefCurrent.scrollTop = scrollHeight - clientHeight;
      }
    }, 100);
  }

  render() {
    const { hasReceivedMessageHistory, messageHistory } = this.props;
    const { historyRef } = this.state;

    return (
      <div
        className={styles.discussion}
        ref={historyRef}
        onScroll={() => {
          this.checkIsDocked();
        }}
      >
        {hasReceivedMessageHistory &&
          messageHistory.map((xx, ix) => (
            <ChatHistoryRow index={ix} key={xx.event_guid} />
          ))}
        <ChatSessionFinished {...this.props} />
      </div>
    );
  }
}

export default ChatHistory;
