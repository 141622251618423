/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { Button } from "dark-matter";
import { getMyId, getUserName, getBearerAuthHeader } from "Identity/selectors";
import { getAppointmentId } from "store/chatendpoints/selectors";
import { getIsVideoSession } from "store/chatconnection/selectors";
import { CheckingVideoAnim } from "./CheckingVideoAnim";
import { useVideoSessionUi } from "./useVideoSessionUi";

// TODO: not in use
export function VideoSessionButton(props) {
  const {
    connectToCall,
    usingVideo,
    canConnect,
    confirmingVideo
  } = useVideoSessionUi(props);

  if (usingVideo) {
    return (
      <Button
        ariaLabel="Connect to Video Call"
        appearance="secondary"
        solid
        size="small"
        onClick={connectToCall}
        disabled={!canConnect || confirmingVideo}
        tackons="border: solid 2px #687382;
        "
      >
        {(() => {
          if (canConnect) {
            return "Connect to Video Call";
          }
          if (confirmingVideo) {
            return <CheckingVideoAnim />;
          }
          return "Video unavailable";
        })()}
      </Button>
    );
  }
  return null;
}

const mapStateToProps = (state) => {
  const appointment_id = getAppointmentId(state);
  const auth_header = getBearerAuthHeader(state);
  const userName = getUserName(state);
  const isVideoSession = getIsVideoSession(state);
  const identity_guid = getMyId(state);

  return {
    appointment_id,
    auth_header,
    userName,
    isVideoSession,
    identity_guid
  };
};

export default connect(mapStateToProps)(VideoSessionButton);
