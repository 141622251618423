/* eslint-disable camelcase */
// Finish appointment modal
// A modal that confirms the end of appointment and submits session feedback

// needs to lock tab in the the modal.
/*
// API 

PATCH /therapy/v1/sessions/{session_id}
Update properties on a session

POST /therapy/v1/sessions/{session_id}/feedback
Creates the user feedback information for the session id provided


// data

session id
authenticated user

// events

ending appointment [cancelled, finish appointment]
finish appointment
cancel ending appointment

show end appointment dialogue
hide end appointment dialogue

feedback form submit


*/

import React from "react";
import { connect } from "react-redux";

import Modal from "Modal/Modal";
import { getSessionStatus } from "store/chatconnection/selectors";
import {
  actions,
  FeedbackUI,
  FeedbackStatus,
  tech_quality,
  overall_service_experience
} from "./data";
import AppointmentFeedback from "./AppointmentFeedback";

function FeedbackModal(props) {
  // eslint-disable-next-line react/prop-types
  const { endingAppointment, cancel } = props;
  return (
    endingAppointment && (
      <Modal close={cancel}>
        <AppointmentFeedback {...props} />
      </Modal>
    )
  );
}

export {
  AppointmentFeedback,
  actions,
  FeedbackUI,
  FeedbackStatus,
  tech_quality,
  overall_service_experience
};

const mapStateToProps = (state) => {
  const sessionStatus = getSessionStatus(state);
  const { endingAppointment } = state.UI.feedback;
  const status = state.UI.feedbackStatus;
  const techQuality = state.UI.tech_quality;
  const overallServiceExperience = state.UI.overall_service_experience;

  return {
    endingAppointment,
    status,
    sessionStatus,
    techQuality,
    overallServiceExperience
  };
};

const mapDispatchToProps = (dispatch) => ({
  cancel: () => dispatch(actions.cancelEndingAppointment()),
  ok: (status) => dispatch(actions.endAppointment(status)),
  setStatus: (status) => dispatch(actions.setStatus(status)),
  setTechQuality: (value) => dispatch(actions.setTechQuality(value)),
  setOverallExperience: (value) =>
    dispatch(actions.setOverallExperience(value)),
  submitSessionFeedback: () => dispatch(actions.submitSessionFeedback())
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackModal);
