import React from "react";
import PropTypes from "prop-types";

import useConfig from "config/useConfig";
import { Button } from "dark-matter";
import stylesModule from "./appointmentHeader.module.scss";

const styles = {
  ...stylesModule,
  online: stylesModule.online,
  offline: stylesModule.offline
};

const Controls = () => {
  const config = useConfig();

  const goHome = () => {
    window.location.replace(config.member_home);
  };

  return (
    <div className={styles.controls}>
      <Button
        outline
        icon="cross"
        secondary
        aria-label="Leave therapy room"
        className={styles.leaveroom}
        id="leave-appointment"
        onClick={() => goHome()}
      >
        Leave therapy room
      </Button>
    </div>
  );
};

AppointmentHeader.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  name: PropTypes.string
};

AppointmentHeader.defaultProps = {
  name: undefined
};

function AppointmentHeader({ isOnline, name }) {
  return (
    <section className={styles.container}>
      <div>
        <span className={styles.therapistName}>{name}</span>
        <div className={styles.info}>
          <div className={isOnline ? styles.online : styles.offline}>
            {isOnline ? "Online" : "Offline"}
          </div>
        </div>
      </div>

      <Controls />
    </section>
  );
}

export default AppointmentHeader;
