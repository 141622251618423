/* eslint-disable camelcase */
import { connect } from "react-redux";

import {
  getMessageHistoryItem,
  getSessionStatus,
  getConnectionStatus
} from "store/chatconnection/selectors";

import {
  SIGNAL_R_CONNECTION_CLOSED,
  REMOVE_RECONNECT_BUTTON
} from "store/chatconnection/types";
import ChatHistoryRow from "./ChatHistoryRow";

function mapStateToProps(state, ownProps) {
  const item = getMessageHistoryItem(state, ownProps);
  return {
    ...ownProps,
    session_in_progress_status: getSessionStatus(state),
    connection_status: getConnectionStatus(state),
    item
  };
}

const mapDispatchToProps = (dispatch) => ({
  reconnect: (event_guid) => {
    dispatch({ type: REMOVE_RECONNECT_BUTTON, event_guid }); // time_utc is used as a key to find the right button to revoke
    return dispatch({
      type: SIGNAL_R_CONNECTION_CLOSED
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatHistoryRow);
