/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable camelcase */
import React, { FC, useRef, useState } from "react";
import { Box, Text } from "darker-matter";
import { useTelemetry } from "ieso-telemetry";
import { useMobileView } from "hooks/useScreenResize.ts";
import { useVideoSessionUi } from "VideoSessionButton/useVideoSessionUi";
import { IMessage, IParticipant } from "AtlasChat/types.ts";
import { StyledHeaderContainerBox } from "AtlasChat/common/styles/StyledHeaderContainerBox";
import { useDownloadTranscript } from "AtlasChat/useTranscriptApi.ts";
import { TELEMETRY_KEYS } from "config/telemetry.ts";
import { useUserId } from "hooks/useUserId.ts";
import {
  getUserConsentStatusApi,
  updateUserConsentStatusApi
} from "AtlasChat/api/userConsent/useUserConsentApi.ts";
import { getTherapyRoomId } from "utils/common";
import { USER_SEEN_VIDEO_WARNING } from "AtlasChat/api/userConsent/constants.ts";
import { IRequiredPolicies } from "AtlasChat/api/userConsent/types.ts";
import ConfirmationModal from "./components/ConfirmationModal.tsx";
import DownloadTranscript from "./components/DownloadTranscript.tsx";
import JoinButton from "./components/JoinButton.tsx";
import TopBar from "../TopBar/TopBar.tsx";
import {
  getParticipants,
  renderBanner,
  useMenuItems,
  ISessionDates,
  getSessionButtonLabel
} from "./helpers.tsx";
import { Modal } from "../Modal/Modal.tsx";
import {
  ChatHeaderLabels,
  ControlsGroup,
  UserMenu,
  SessionButton
} from "./styles.ts";

interface IChatHeaderProps {
  otherParticipants: IParticipant[];
  isParticipant: boolean;
  isOwner: boolean;
  sessionId: string;
  sessionStatus: string;
  patient: IParticipant;
  auth_header: string;
  userName: string;
  isVideoSession: boolean;
  sessionEndDate?: string;
  isSessionActive: boolean;
  hasClinicians: boolean;
  readonlyMode: boolean;
  messages: IMessage[];
  roles: string[];
  myName: string;
  connectionStatus: string;
  updateIsSessionActive: (isActive: boolean) => void;
}

const ChatHeader: FC<IChatHeaderProps> = ({
  otherParticipants,
  isParticipant,
  isOwner,
  sessionId,
  sessionStatus,
  auth_header,
  userName,
  patient,
  isVideoSession,
  sessionEndDate,
  isSessionActive,
  hasClinicians,
  messages,
  readonlyMode,
  roles,
  myName,
  connectionStatus,
  updateIsSessionActive
}) => {
  const userId = useUserId();
  const telemetry = useTelemetry();
  const { connectToCall, canConnect } = useVideoSessionUi({
    appointment_id: sessionId,
    auth_header,
    userName,
    isVideoSession
  });
  const mobileView = useMobileView();
  const { mutateAsync } = useDownloadTranscript(sessionId);
  const [session, setSession] = useState<
    ISessionDates[] | ISessionDates | null
  >(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const isStart = !isSessionActive;
  const isPatient =
    roles === undefined || roles.length === 0 || roles.includes("Member");
  const isClinician =
    roles !== undefined && roles.length > 0 && roles.includes("Agent");
  const isGlobalAdmin =
    roles !== undefined && roles.length > 0 && roles.includes("GlobalAdmin");
  const patientIsDischarged = sessionEndDate !== null;
  const userMenuItems = useMenuItems(isClinician);
  const downloadBtnRef = useRef<HTMLButtonElement>(null);
  const { data } = getUserConsentStatusApi();
  const policies = data as IRequiredPolicies;
  const requiredPolicies = policies?.requiredPolicies ?? [];
  const isConsentAccepted =
    !isPatient ||
    !requiredPolicies?.some(
      (policy) => policy.name === USER_SEEN_VIDEO_WARNING
    );
  const therapyRoomId = getTherapyRoomId();

  const mutation = updateUserConsentStatusApi(
    USER_SEEN_VIDEO_WARNING,
    connectToCall
  );
  const onAccept = () => {
    mutation.mutateAsync();
  };

  const handleConnectToCall = () => {
    telemetry.trackEvent(TELEMETRY_KEYS.CONNECT_TO_VIDEO_SESSION, {
      userId,
      therapyRoomId
    });
    connectToCall();
  };

  const headerConfig = getParticipants({
    isClinician,
    isGlobalAdmin,
    isPatient,
    messages,
    otherParticipants,
    patient
  });

  return (
    <Box>
      <StyledHeaderContainerBox>
        {sessionStatus && (
          <ChatHeaderLabels>
            {headerConfig.map((participant) => (
              <Box key={participant.name} display="flex" flexDirection="column">
                <Text fontWeight="bold" truncated="100%">
                  {participant.name}
                </Text>
                {participant.label && participant.name && (
                  <Text textStyle="small" color="ink.light" truncated="100%">
                    {participant.label}
                  </Text>
                )}
              </Box>
            ))}
          </ChatHeaderLabels>
        )}
        <UserMenu>
          {(!sessionEndDate || isPatient) && (
            <ControlsGroup>
              {isPatient && (
                <DownloadTranscript
                  messages={messages}
                  onSelect={setSession}
                  ref={downloadBtnRef}
                />
              )}
              {!isStart && canConnect && isParticipant && (
                <JoinButton
                  connectToCall={handleConnectToCall}
                  mobileView={mobileView}
                  disabled={connectionStatus !== "Connected"}
                  isConsentAccepted={isConsentAccepted}
                  onAccept={onAccept}
                />
              )}
              {isOwner && !readonlyMode && (
                <SessionButton
                  ariaLabel={isStart ? "Start session" : "End session"}
                  ariaDescription={
                    isStart ? "Start your session" : "End your session"
                  }
                  magnitude="small"
                  $isStart={isStart}
                  appearance={isStart ? "primary" : "destructive"}
                  disabled={connectionStatus !== "Connected"}
                  onClick={() => setShowConfirmationModal(true)}
                >
                  {getSessionButtonLabel(isStart, mobileView)}
                </SessionButton>
              )}
            </ControlsGroup>
          )}
          {myName && isParticipant && (
            <TopBar.UserMenu
              name={myName}
              appearance={isPatient ? "member" : "therapist"}
              id="user-menu"
              sections={userMenuItems}
            />
          )}
        </UserMenu>
      </StyledHeaderContainerBox>
      {renderBanner({
        hasClinicians,
        isClinician,
        isGlobalAdmin,
        isOwner,
        isParticipant,
        isPatient,
        isSessionActive,
        patientIsDischarged,
        sessionEndDate
      })}
      <ConfirmationModal
        isStart={isStart}
        open={showConfirmationModal}
        onAction={() => {
          updateIsSessionActive(isSessionActive);
          setShowConfirmationModal(false);
        }}
        onClose={() => setShowConfirmationModal(false)}
      />
      <Modal
        maxWidth="696px"
        showModal={!!session}
        headerContent={
          Array.isArray(session) ? "Download all" : `Download ${session?.label}`
        }
        buttonLabel="Download"
        buttonAppearance="primary"
        onProceed={() => {
          if (Array.isArray(session)) {
            session.forEach((s) => mutateAsync(s));
          } else {
            mutateAsync(session);
          }
          setSession(null);
          telemetry.trackEvent(TELEMETRY_KEYS.DOWNLOAD_SESSION, {
            userId,
            therapyRoomId
          });
          setTimeout(() => downloadBtnRef.current?.focus(), 0);
        }}
        onCancel={() => {
          setSession(null);
          setTimeout(() => downloadBtnRef.current?.focus(), 0);
        }}
      >
        <Box mb={6}>
          You’re about to download a text file of your conversation with your
          therapist to your device. Please be careful with the file once you’ve
          downloaded it, we can’t ensure its security once outside of our
          system.
        </Box>
      </Modal>
    </Box>
  );
};

export default ChatHeader;
