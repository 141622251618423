/* eslint-disable prefer-object-spread */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import {
  reducer as chatconnection,
  middleware as chatconnectionmiddleware,
  NAME as chatconnectionName
} from "store/chatconnection";
import { loggingMiddleware } from "store/logging/middleware";
import identity from "Identity/reducers";
import { createScrollingMiddleware, chatReducer as chat } from "Chat";
import {
  FeedbackUI as feedback,
  FeedbackStatus as feedbackStatus,
  tech_quality,
  overall_service_experience
} from "AppointmentFeedback";
import loading from "Loading/data/reducer";
import { reducer as chatendpoints } from "./chatendpoints";
import { reducer as telemetryReducer } from "./telemetry";

const { window } = global;

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const UI = combineReducers({
  chat,
  feedback,
  feedbackStatus,
  tech_quality,
  overall_service_experience,
  loading
});

const dead_state = {};

if (global.__ieso_config.dead_state) {
  Object.keys(global.__ieso_config.dead_state).forEach((xx) => {
    dead_state[xx] = () => global.__ieso_config.dead_state[xx];
  });
}

const middlewares = [
  global.__ieso_config.log_redux_actions && createLogger({ collapsed: true }),
  thunkMiddleware,
  chatconnectionmiddleware.createMiddleware(),
  createScrollingMiddleware(),
  loggingMiddleware
].filter(Boolean);

export default function configureStore({ telemetry, therapyRoomId }) {
  let store;
  const rootReducer = combineReducers(
    Object.assign(
      {
        chatendpoints,
        identity,
        UI,
        [chatconnectionName]: chatconnection(therapyRoomId),
        telemetry: telemetryReducer
      },
      {
        ...dead_state
      }
    )
  );

  if (document.location.pathname === "/silent_renew") {
    store = createStore(
      combineReducers({ identity, telemetry: telemetryReducer, ...dead_state }),
      {
        telemetry
      },
      composeEnhancers()
    );
  } else {
    store = createStore(
      rootReducer,
      {
        telemetry
      },
      composeEnhancers(applyMiddleware(...middlewares))
    );
  }
  return store;
}
