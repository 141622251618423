import styled from "styled-components";
import { css } from "@styled-system/css";
import { Box, Text } from "darker-matter";
import { ReactComponent as TimeCalendar } from "shared/icons/time-calendar.svg";
import { linkButtonMixin } from "shared/mixins/buttons";
import { Button } from "AtlasChat/components/Button/button";

export const CollapsibleLabel = styled(Box)(
  css({
    display: "flex",
    alignItems: "center",
    padding: "12px 0",
    "@media screen and (max-width: 640px)": {
      padding: "6px 0"
    }
  })
);

export const CollapsibleLabelControls = styled.div(
  css({
    display: "flex",
    alignItems: "center",
    width: "100%"
  })
);

export const ExpandButton = styled(Button)(
  linkButtonMixin,
  css({
    padding: "6px 10px",
    whiteSpace: "nowrap"
  })
);

export const Line = styled.div<{ $width?: string }>(({ $width = "100%" }) =>
  css({ width: $width, height: "1px", backgroundColor: "ink.lighter" })
);

export const CollapsibleTitle = styled(Text)(
  css({ fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" })
);

export const StyledTimelineBox = styled.div<{
  $collapsed?: boolean;
  $height?: number;
}>`
  ${({ $collapsed, $height }) => {
    const height = $height ? `${$height}px` : "auto";
    return `
      height: ${$collapsed ? "96px" : height};
      padding: 0 4px;
      position: relative;
      overflow-y: ${$collapsed ? "hidden" : "visible"};


      @supports (overflow-y: clip) {
        overflow-y: clip;
      }

      transition: height 0.2s;
      
      @media screen and (max-width: 640px) {
        height: ${$collapsed ? "84px" : height};
      }
    `;
  }}
`;

export const TimeLineBorder = styled.div<{ $collapsed?: boolean }>(
  ({ $collapsed }) =>
    css({
      display: $collapsed ? "none" : "block",
      width: "2px",
      borderRadius: "8px",
      backgroundColor: "primary.dark",
      position: "absolute",
      top: "30px",
      bottom: "30px",
      left: "-4px"
    })
);

export const Tag = styled.div(
  css({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginRight: "4px",
    padding: "4px 16px",
    color: "ink.light",
    "&:last-child": {
      marginRight: 0
    }
  })
);

export const IconLabel = styled(Text)`
  font-size: 14px;
`;

export const TimeCalendarIcon = styled(TimeCalendar)(
  css({ width: "16px", height: "16px", color: "ink.light" })
);
