import React from "react";
import Initials from "initials";
import PropTypes from "prop-types";

import styles from "./avatar.module.scss";

Avatar.propTypes = {
  isMe: PropTypes.bool,
  name: PropTypes.string,
  onlineStatus: PropTypes.bool,
  showOnlineStatus: PropTypes.bool
};
Avatar.defaultProps = {
  name: "",
  isMe: false,
  onlineStatus: false,
  showOnlineStatus: false
};

function Avatar({ isMe, name, onlineStatus, showOnlineStatus }) {
  const nameWithoutBracket = name?.replace(/\((.*?)\)/g, "");
  const initials = Initials(nameWithoutBracket);
  // Our test accounts are shown as (test) because initials picks these as 'preferred initials' https://github.com/gr2m/initials#notes
  return (
    <div
      className={`${styles.container} ${
        isMe ? styles.patient : styles.therapist
      }`}
    >
      {initials}
      {showOnlineStatus && (
        <span className={onlineStatus ? styles.online : styles.offline} />
      )}
    </div>
  );
}

export default Avatar;
