import React, { useState } from "react";
import { Icon } from "dark-matter";
import VideoSessionButton from "VideoSessionButton";
import { ReactComponent as ActiveEmoji } from "./emoji-big-smile.svg";
import ChatTextBox from "../ChatTextbox/ChatTextbox";
import styles from "./compose.module.scss";

function ChatActions() {
  const [showEmoji, setShowEmoji] = useState(false);
  return (
    <div className={styles.container}>
      <ChatTextBox showEmoji={showEmoji} setShowEmoji={setShowEmoji} />

      <div className={styles.wrapper}>
        <div className={styles.controls}>
          <button
            type="button"
            className={styles.emojiButton}
            onClick={() => setShowEmoji(!showEmoji)}
          >
            {showEmoji ? (
              <ActiveEmoji />
            ) : (
              <Icon
                name="emoji"
                width="28px"
                height="28px"
                accessibilityTitle="emoji"
              />
            )}
          </button>
        </div>
        <VideoSessionButton />
      </div>
    </div>
  );
}

export default ChatActions;
