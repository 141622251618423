// Component is duplicated from darker-matter to handle accessibility issues in scope of CDP-6964

import React, { useRef } from "react";
import styled from "styled-components";

import { Avatar, Box, MemberTypes } from "darker-matter";

import {
  IBoxComponentProps,
  IButtonComponentProps
} from "darker-matter/build/shared-types";
import { IActionListButton } from "darker-matter/build/components/ActionList/components/Button";
import { IActionListLink } from "darker-matter/build/components/ActionList/components/Link";
import { IActionListSection } from "darker-matter/build/components/ActionList/ActionList";
import ActionList from "AtlasChat/components/ActionList/ActionList";
import { useArrowNavigation } from "AtlasChat/hooks/useArrowNavigation.ts";

import { buttonResetStyleProps } from "styles/shared.ts";
import { userMenuStyles } from "./styles.ts";
import usePopover from "../../../Popover/Popover.tsx";

interface IUserMenuProps extends IBoxComponentProps {
  /**
   * Mandatory - used to tie the trigger to the popover element
   */
  id: string;
  /**
   * Avatars are colour schemed around user type
   */
  appearance?: MemberTypes;
  /**
   * Screen label for the user menu trigger
   */
  ariaLabel?: string;
  /**
   * Content to be displayed inside the Popover
   */
  children?: never;
  /**
   * Array of objects describing each item in an ActionList to be displayed in a popover. Use this, the sections prop, or children to render items in the list.
   */
  items?: (IActionListLink | IActionListButton)[];
  /**
   * Array of sections - allows item collections to display a header and to be separated by a dividing line.
   */
  sections?: IActionListSection[];
  /**
   * Member name to be used for the avatar initials
   */
  name: string;
  /**
   * Prop set by the parent TopBar component to position the popover underneath the container
   * */
  offset?: [number, number];
}

const StyledTrigger = styled("button")<
  IButtonComponentProps & { $appearance: MemberTypes }
>(buttonResetStyleProps, ({ $appearance }) => userMenuStyles($appearance));

const UserMenu: React.FC<IUserMenuProps> = ({
  appearance = "member",
  ariaLabel,
  items,
  sections,
  name,
  offset = [0, 16],
  id,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { Popover, hide, isShow } = usePopover(id);
  const { handleKeyDown } = useArrowNavigation({
    isShow,
    hide,
    ref
  });

  const TriggerMarkup = (
    <StyledTrigger
      aria-label={ariaLabel || "Menu"}
      aria-haspopup="true"
      aria-description="Menu options"
      aria-expanded="false"
      $appearance={appearance}
    >
      <Avatar name={name} appearance={appearance} aria-label="Menu" />
    </StyledTrigger>
  );

  return (
    <>
      <Box
        gridArea="altmenus"
        gridRow={1}
        id={id}
        {...props}
        onKeyDown={(e) => handleKeyDown((e as unknown) as KeyboardEvent)}
      >
        <Popover
          trigger={TriggerMarkup}
          placement="bottom-end"
          offset={offset}
          id={id}
          {...props}
        >
          <div ref={ref}>
            <ActionList items={items} sections={sections} onItemClick={hide} />
          </div>
        </Popover>
      </Box>
    </>
  );
};

export default UserMenu;
