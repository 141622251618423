import React, { FC } from "react";
import { Box } from "darker-matter";
import { ConnectionStatus, Timeline } from "AtlasChat/components";
import { ModalProvider } from "../Timeline/ModalContext.tsx";

interface ChatContainerProps {
  containerRef: React.RefObject<HTMLDivElement>;
}

const ChatContainer: FC<ChatContainerProps> = ({ containerRef }) => (
  <Box position="relative">
    <ConnectionStatus />
    <ModalProvider>
      <Timeline containerRef={containerRef} />
    </ModalProvider>
  </Box>
);
export default ChatContainer;
