import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { Box, Brand, ButtonGroup, Heading, Text } from "darker-matter";
import {
  ParagraphsBox,
  ParticipantButton,
  SupervisorButton,
  StyledContentBox,
  StyledRestrictedViewHeaderBox
} from "./styles.ts";
import Layout from "../Layout.tsx";

const RestrictedChatView: FC<{
  stateData: {
    header: string;
    paragraphs: (string | React.ReactElement)[];
    showJoinButton?: boolean;
    showViewAsSupervisorButton?: boolean;
    imageSrc: string;
  };
  addParticipant: (referralId: string) => void;
  viewAsSupervisor: (referralId: string) => void;
}> = ({ stateData, addParticipant, viewAsSupervisor }) => {
  const { guid: referralId } = useParams();
  const {
    header,
    paragraphs,
    showJoinButton,
    showViewAsSupervisorButton,
    imageSrc
  } = stateData;

  if (!header) {
    return null;
  }

  return (
    <Layout>
      <StyledRestrictedViewHeaderBox justifyContent="center">
        <Brand width="120px" />
      </StyledRestrictedViewHeaderBox>
      <Box bg="neutral.main" overflowY="auto" flexGrow={1} p={[5, 5, 10]}>
        <StyledContentBox>
          <Box>
            <Heading textStyle="level2" mb={5}>
              {header}
            </Heading>
            <ParagraphsBox>
              {paragraphs?.map((p) => (
                <Text as="p" textStyle="large">
                  {p}
                </Text>
              ))}
            </ParagraphsBox>
            <ButtonGroup>
              {showJoinButton && (
                <ParticipantButton
                  appearance="primary"
                  my={5}
                  onClick={() => addParticipant(referralId)}
                  data-testid="join-session"
                >
                  Join session
                </ParticipantButton>
              )}
              {showViewAsSupervisorButton && (
                <SupervisorButton
                  appearance="primary"
                  my={5}
                  onClick={() => viewAsSupervisor(referralId)}
                  data-testid="view-as-supervisor"
                >
                  View as supervisor
                </SupervisorButton>
              )}
            </ButtonGroup>
          </Box>
          <Box textAlign="center" my="auto">
            <img src={imageSrc} alt="Session availability" />
          </Box>
        </StyledContentBox>
      </Box>
    </Layout>
  );
};

export default RestrictedChatView;
