import styled from "styled-components";
import { css } from "@styled-system/css";
import { Box } from "darker-matter";

export const StyledHeaderContainerBox = styled(Box)(
  css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "4px",
    minHeight: ["auto", "auto", "110px"],
    py: 3,
    px: [5, 5, 6, 8],
    bg: "neutral.lighter"
  })
);
