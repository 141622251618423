import React from "react";
import PropTypes from "prop-types";

import Styles from "./chat.module.scss";
import ChatActions from "./Compose/Compose";
import ChatHistory from "./ChatHistory";
import ConnectionStatus from "./ConnectionStatus/ConnectionStatus";
import ScrollButton from "./ScrollButton";

ChatWindow.propTypes = {
  connectionStatus: PropTypes.string,
  isReconnected: PropTypes.bool,
  messagesLength: PropTypes.number,
  onReconnectClick: PropTypes.func,
  shouldShowConnectionStatus: PropTypes.bool,
  showReconnectButton: PropTypes.bool,
  userId: PropTypes.string,
  sessionStatus: PropTypes.string,
  otherUser: PropTypes.string
};

ChatWindow.defaultProps = {
  connectionStatus: undefined,
  isReconnected: false,
  messagesLength: 0,
  onReconnectClick: undefined,
  shouldShowConnectionStatus: false,
  showReconnectButton: false,
  userId: undefined,
  sessionStatus: undefined,
  otherUser: undefined
};

function ChatWindow(props) {
  const {
    connectionStatus,
    isReconnected,
    messagesLength,
    onReconnectClick,
    shouldShowConnectionStatus,
    showReconnectButton,
    userId
  } = props;

  return (
    <div className={Styles.container}>
      <ChatActions />
      <div className={Styles.discussionContainer}>
        <div className={Styles.scrollOverlay}>
          <ConnectionStatus
            shouldShow={shouldShowConnectionStatus}
            isReconnected={isReconnected}
            statusString={connectionStatus}
            showReconnectButton={showReconnectButton}
            onReconnectClick={onReconnectClick}
          />
          {messagesLength > 0 && <ScrollButton {...props} />}
        </div>
        <ChatHistory
          {...props}
          myGuid={userId}
          connectionStatus={connectionStatus}
        />
      </div>
    </div>
  );
}

export default ChatWindow;
