import styled from "styled-components";
import { css } from "@styled-system/css";
import { StyledHeaderContainerBox } from "AtlasChat/common/styles/StyledHeaderContainerBox";
import { Box, Button } from "darker-matter";
import { buttonMixin } from "shared/mixins/buttons";

export const StyledRestrictedViewHeaderBox = styled(StyledHeaderContainerBox)(
  css({
    justifyContent: "center"
  })
);

export const StyledContentBox = styled(Box)(
  css({
    display: "flex",
    flexDirection: ["column-reverse", "column-reverse", "row"],
    justifyContent: "center",
    gap: 7,
    maxWidth: ["none", "none", "1000px"],
    mt: [4, 4, 6],
    mx: [0, 6, "auto"],
    "> *": {
      flexGrow: 1,
      flexBasis: "50%"
    }
  })
);

export const ParagraphsBox = styled(Box)(
  css({
    "> *": {
      mb: 3
    }
  })
);

export const ParticipantButton = styled(Button)`
  ${buttonMixin("primary")}
`;

export const SupervisorButton = styled(Button)(
  buttonMixin("ink"),
  css({
    color: "white",
    "&:hover": {
      color: "white"
    }
  })
);
