import React from "react";
import { SessionAvailability } from "AtlasChat/types";
import { Text } from "darker-matter";
import openedDoorSrc from "./icons/openedDoor.svg";
import closedDoorSrc from "./icons/closedDoor.svg";

const TherapistContactUsEmail = () => (
  <Text textStyle="large" as="span">
    therapist@iesohealth.com
  </Text>
);

export const states = {
  [SessionAvailability.UnknownClinitian]: {
    header: "Sorry the page you are looking for cannot be found",
    paragraphs: [
      <>
        The therapy room you are trying to access does not exist or you do not
        have permission to view it. If you believe this is an error, please
        email us at <TherapistContactUsEmail />.
      </>
    ],
    imageSrc: closedDoorSrc
  },
  [SessionAvailability.UnknownPatient]: {
    header: "Sorry, the page you are looking for cannot be found",
    paragraphs: [
      `The page you are looking for is not available. If you need further
      support please email us at info@iesohealth.com.`
    ],
    imageSrc: closedDoorSrc
  },
  [SessionAvailability.AwaitingClinician]: {
    header: "Awaiting assigned clinician",
    paragraphs: [
      "Welcome to your therapy session chatroom. This chat will become available once your clinician has been assigned.",
      "Please wait patiently. We appreciate your understanding and look forward to assisting you soon."
    ],
    imageSrc: openedDoorSrc
  },
  [SessionAvailability.Joinable]: {
    header: "Welcome to your patient’s therapy room",
    paragraphs: [
      "By entering, you are confirming that you are this patient’s clinician. You must not bookmark this tab and should only access this room via iaptus or ‘My patients’.",
      "This transcript is strictly confidential. Please don’t copy or share it.",
      <>
        If you encounter any technical issues, please contact{" "}
        <TherapistContactUsEmail />.
      </>
    ],
    showJoinButton: true,
    imageSrc: openedDoorSrc
  },
  [SessionAvailability.JoinableAsSupervisor]: {
    header: "Welcome to your patient’s therapy room",
    paragraphs: [
      "By entering, you are confirming that you are this patient’s clinician. You must not bookmark this tab and should only access this room via iaptus or ‘My patients’.",
      "This transcript is strictly confidential. Please don’t copy or share it.",
      <>
        If you encounter any technical issues, please contact{" "}
        <TherapistContactUsEmail />.
      </>
    ],
    showJoinButton: true,
    showViewAsSupervisorButton: true,
    imageSrc: openedDoorSrc
  },
  [SessionAvailability.NoLongerHaveAccess]: {
    header: "Sorry, you no longer have access to this therapy room",
    paragraphs: [
      <>
        The therapy room is no longer available as this patient has been
        discharged for over 6 weeks. If you believe this is an error, please
        email us at <TherapistContactUsEmail />.
      </>
    ],
    imageSrc: closedDoorSrc
  }
};
