import React, { useState } from "react";
import PropTypes from "prop-types";
import ContentEditable from "react-contenteditable";
import { Button } from "dark-matter";

import Spinner from "Spinner/Spinner";
import { ReactComponent as CloseIcon } from "shared/icons/close.svg";
import styles from "./appointmentFeedback.module.scss";

const attendanceOptions = [
  {
    id: "completeAttended",
    value: "Patient attended",
    label: "Patient attended"
  },
  {
    id: "completeLate",
    value: "Patient attended late",
    label: "Patient attended late"
  },
  {
    id: "completeDNA",
    value: "Patient did not attend",
    label: "Patient did not attend"
  },
  {
    id: "completeAborted",
    value: "Appointment abandoned for technical reasons",
    label: "Appointment abandoned for technical reasons"
  }
];

const techQualityOptions = [
  {
    id: "ratingExcellent",
    value: 5,
    label: "Excellent (No problems)"
  },
  {
    id: "ratingGood",
    value: 4,
    label: "Good (Minor issues, did not affect the session)"
  },
  {
    id: "ratingFair",
    value: 3,
    label: "Fair (Issues that affected the session)"
  },
  {
    id: "ratingPoor",
    value: 2,
    label: "Poor (Serious issues, session disrupted)"
  },
  {
    id: "ratingVeryBad",
    value: 1,
    label: "Very Bad (Session was impossible)"
  }
];

Feedback.propTypes = {
  cancel: PropTypes.func.isRequired,
  ok: PropTypes.func.isRequired,
  overallServiceExperience: PropTypes.string,
  sessionStatus: PropTypes.string.isRequired,
  setOverallExperience: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setTechQuality: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  submitSessionFeedback: PropTypes.func.isRequired,
  techQuality: PropTypes.string.isRequired
};

Feedback.defaultProps = {
  overallServiceExperience: null
};

// TODO: not in use
function Feedback(props) {
  const {
    cancel,
    ok,
    overallServiceExperience,
    sessionStatus,
    setOverallExperience,
    setStatus,
    setTechQuality,
    status,
    submitSessionFeedback,
    techQuality
  } = props;

  const [loading, setLoading] = useState(false);

  switch (sessionStatus) {
    case "finished_redirect_therapist":
      return (
        <div className={styles.container}>
          <div className={styles.waiting}>
            <Spinner className={styles.spinner} />
            <span className={styles.waitingMessage}>
              Submitting your feedback &hellip;
            </span>
          </div>
        </div>
      );
    case "finished_feedback_required":
      return (
        <div className={styles.container}>
          <header className={styles.header}>
            <div className={styles.title}>Submit Session Feedback</div>
          </header>
          <p className={styles.label}>
            Please rate the technical quality of the session:
          </p>

          <ul className={styles.radioList}>
            {techQualityOptions.map((xx) => (
              <li key={xx.id} className={styles.radio}>
                <input
                  checked={xx.id === techQuality}
                  id={xx.id}
                  name="tech_quality"
                  onChange={() => setTechQuality(xx.id)}
                  type="radio"
                  value={xx.value}
                />
                <label htmlFor={xx.id}>{xx.label}</label>
              </li>
            ))}
          </ul>

          <p className={styles.label}>
            Please add any additional comments about the overall experience:
          </p>

          <ContentEditable
            className={styles.input}
            disabled={false}
            html={overallServiceExperience}
            onChange={(x) => setOverallExperience(x.target.value)}
          />
          <div className={styles.footer}>
            <Button
              className={styles.submit}
              disabled={techQuality === ""}
              onClick={() => submitSessionFeedback(status)}
              type="button"
            >
              Submit
            </Button>
          </div>
        </div>
      );
    default:
      return (
        <div className={styles.container}>
          <header className={styles.header}>
            <div className={styles.title}>End this appointment?</div>
            <Button
              className={styles.close}
              onClick={() => cancel()}
              type="button"
              appearance="secondary"
            >
              <CloseIcon className={styles.closeIcon} />
            </Button>
          </header>

          <p>This will end the appointment</p>
          <p className={styles.label}>Did the patient attend?</p>
          <ul className={styles.radioList}>
            {attendanceOptions.map((xx) => (
              <li key={xx.id} className={styles.radio}>
                <input
                  checked={xx.id === status}
                  id={xx.id}
                  name="attendance"
                  onChange={() => setStatus(xx.id)}
                  type="radio"
                  value={xx.value}
                />
                <label className={styles.radioLabel} htmlFor={xx.id}>
                  {xx.label}
                </label>
              </li>
            ))}
          </ul>
          <footer className={styles.footer}>
            <Button
              className={styles.cancel}
              onClick={() => cancel()}
              type="button"
              appearance="secondary"
            >
              Cancel
            </Button>
            <Button
              className={styles.finish}
              disabled={status === ""}
              onClick={() => {
                setLoading(true);
                ok(status);
              }}
              outline
              type="button"
              appearance="primary"
              loading={loading}
            >
              End Appointment
            </Button>
          </footer>
        </div>
      );
  }
}

export default Feedback;
