/* eslint-disable */
import React from "react";
import styled from "styled-components";
import {
  IAnchorComponentProps,
  ILinkableAction,
  Icon,
  Text
} from "darker-matter";
import { coreStyleFeatures } from "styles/shared-style-props.ts";
import { IActionItemSharedProps } from "../types.ts";
import { actionStyles } from "../styles.ts";

export type IActionListLink = IAnchorComponentProps &
  IActionItemSharedProps &
  ILinkableAction;

const StyledLink = styled("a")<IActionListLink>(
  actionStyles,
  coreStyleFeatures
);

export const Link: React.FC<IActionListLink> = ({
  ariaLabel,
  ariaDescription,
  destructive = false,
  external = false,
  download,
  id,
  label,
  icon,
  url,
  ...props
}) => {
  return (
    // fails eslint even though it's correctly implemented
    // eslint-disable-next-line react/jsx-no-target-blank

    <StyledLink
      $color={destructive ? "error.main" : "ink.main"}
      {...props}
      id={id}
      href={url}
      target={external ? "_blank" : null}
      rel={external ? "noopener noreferrer" : null}
      aria-label={ariaLabel}
      aria-description={ariaDescription}
      download={download}
      {...props}
    >
      {icon && (
        <Icon
          verticalAlign="bottom"
          name={icon}
          decorative
          width="16px"
          height="16px"
          flexShrink={0}
          mr={3}
        />
      )}
      <Text textStyle="small" flexGrow={1} $color="currentColor">
        {label}
      </Text>
      {external && (
        <Icon
          verticalAlign="bottom"
          name="opens-new-window"
          decorative
          width="12px"
          height="12px"
          flexShrink={0}
          $color="ink.light"
          ml={3}
        />
      )}
    </StyledLink>
  );
};
