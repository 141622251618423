/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";

import styles from "./modal.module.scss";

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  onClose: PropTypes.any
};

const handleClose = (evt, target, closeFn) => {
  evt.target === document.getElementById(target) && closeFn();
};

function Modal({ children, close }) {
  return (
    <div
      className={styles.modal}
      id={styles.modal}
      onClick={(evt) => {
        handleClose(evt, styles.modal, close);
      }}
      onKeyPress={(evt) => {
        handleClose(evt, styles.modal, close);
      }}
      role="presentation"
    >
      <div aria-modal="true" className={styles.modalInner} role="dialog">
        {children}
      </div>
    </div>
  );
}

export default Modal;
