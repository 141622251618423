import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ComputerSays } from "dark-matter";
import { setLoading } from "Loading/data/actions";
import { connect } from "react-redux";

Page404.propTypes = {
  stopLoading: PropTypes.func.isRequired
};

function Page404(props) {
  const { stopLoading } = props;

  useEffect(() => {
    stopLoading();
  }, [stopLoading]);
  document.title = "Page not found";
  return (
    <ComputerSays
      appearance="info"
      title="Page not found"
      icon="info-circle"
      message="Please go back and try again."
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  stopLoading: () => dispatch(setLoading(false))
});

export default connect(undefined, mapDispatchToProps)(Page404);
