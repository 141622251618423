/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "styled-components";
import { css } from "@styled-system/css";
import { Box, TextField } from "darker-matter";
import { buttonMixin, linkButtonMixin } from "shared/mixins/buttons";
import { Button } from "AtlasChat/components/Button/button";
import IconButton from "AtlasChat/components/IconButton/iconButton";

export const BottomPanelContainer = styled.div(
  css({
    position: "sticky",
    width: ["auto", "auto", "700px"],
    bottom: 0,
    mx: [3, 5, "auto"],
    marginBottom: 4,
    boxShadow: "elevation1",
    borderRadius: "xl",
    bg: "neutral.light"
  })
);

export const Editor = styled(Box)(
  css({
    mb: "1px",
    borderRadius: "0px",
    borderTopLeftRadius: "xl",
    borderTopRightRadius: "xl",
    bg: "neutral.lighter",
    "&:focus-within": {
      boxShadow: "focusRing"
    }
  })
);

export const TextArea = styled(TextField)(
  css({
    display: "flex",
    "& > textarea": {
      resize: "none",
      maxHeight: "240px",
      minHeight: "60px",
      pt: 4,
      pl: 4,
      pr: 0,
      mr: 4,
      border: "none",
      borderRadius: "0px",
      borderTopLeftRadius: "xl",
      borderTopRightRadius: "xl",
      "&:focus": {
        boxShadow: "none"
      }
    }
  })
);

export const ToolbarButton = styled(IconButton)`
  &:focus {
    box-shadow: 0 0 0 2px #017a67, 0 0 0 2px #ffffff inset;
  }
`;

export const RemoveAttachButton = styled(Button)(
  linkButtonMixin,
  css({ marginLeft: "-2px" })
);

export const SendButtonContainer = styled(Box)(
  css({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "relative"
  })
);

export const SendButton = styled(Button).withConfig({
  shouldForwardProp: (name) => !["isTextEmpty"].includes(name)
})(buttonMixin as any, css({ padding: "12px 24px", marginRight: "8px" }));

export const Toolbar = styled("div")(
  css({
    display: "flex",
    bg: "neutral.dark",
    borderBottomLeftRadius: "xl",
    borderBottomRightRadius: "xl",
    "& > button > svg, & > div > div > button > svg": {
      color: "ink.main"
    }
  })
);
export const HiddenInput = styled("input")(
  css({
    display: "none"
  })
);

export const AttachmentsContainer = styled(Box)(
  css({
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "184px",
    overflowY: "auto",
    px: 4,
    py: 2
  })
);
