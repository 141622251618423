import React from "react";
import PropTypes from "prop-types";

import styles from "./spinner.module.scss";

Spinner.propTypes = {
  className: PropTypes.string
};

Spinner.defaultProps = {
  className: null
};

function Spinner({ className }) {
  return (
    <svg
      className={`${styles.svg} ${className || ""}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 54 54"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fillRule="evenodd"
        d="M27,0 C28.6568542,0 30,1.34314575 30,3 C30,4.65685425 28.6568542,6 27,6 C15.4020203,6 6,15.4020203 6,27 C6,38.5979797 15.4020203,48 27,48 C32.6246595,48 37.8909744,45.782645 41.8017591,41.8965754 C42.9770398,40.7287222 44.8765252,40.7347433 46.0443785,41.9100241 C47.2122317,43.0853048 47.2062106,44.9847902 46.0309298,46.1526434 C41.0071568,51.1446675 34.2256176,54 27,54 C12.0883118,54 0,41.9116882 0,27 C0,12.0883118 12.0883118,0 27,0 Z"
      />
    </svg>
  );
}

export default Spinner;
