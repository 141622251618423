import React, { FC, useEffect, useMemo, useRef } from "react";
import { IParticipant, ITimelineEvent } from "AtlasChat/types";
import { useTimeline } from "./useTimeline.ts";
import DateChip from "./components/DateChip.tsx";
import CollapsibleTimeline from "./components/CollapsibleTimeline/CollapsibleTimeline.tsx";
import { DeleteMessageModal } from "./DeleteMessageModal.tsx";
import {
  TSessions,
  extractSessions,
  formMessagesInSession,
  renderMessage,
  getNewEvent
} from "./helpers.tsx";
import { StyledTimelineBox } from "./styles.ts";

interface ITimelineProps {
  sessionId: string;
  messageHistory: ITimelineEvent[];
  allParticipants: IParticipant[];
  isSessionOwner: boolean;
  typingMessage: ITimelineEvent | null;
  setChatTimeline: (timeline: ITimelineEvent[]) => void;
  containerRef?: React.RefObject<HTMLDivElement>;
  connectionStatus: string;
  roles: string[];
}

const Timeline: FC<ITimelineProps> = ({
  sessionId,
  messageHistory,
  typingMessage,
  allParticipants,
  isSessionOwner,
  setChatTimeline,
  containerRef,
  connectionStatus,
  roles
}) => {
  const { data } = useTimeline(sessionId);
  const prevMsgHistory = useRef<ITimelineEvent[]>(messageHistory);
  const prevSessions = useRef<TSessions>([]);
  const intersectingRefs = useRef({});
  const messagesBottomRef = useRef<HTMLDivElement>();
  const firstTimestampIndex = useRef(Number.MAX_SAFE_INTEGER);
  const isSysAdmin = roles.includes("SysAdmin");

  useEffect(() => {
    if (data) {
      setChatTimeline(data);
      prevMsgHistory.current = data;
      prevSessions.current = extractSessions([], data, []);
    }
  }, [data]);

  useEffect(() => {
    if (messagesBottomRef.current) {
      window.requestAnimationFrame(() =>
        messagesBottomRef.current.scrollIntoView({ behavior: "instant" })
      );
    }
  }, [messageHistory.length, typingMessage]);

  const newEvents = getNewEvent(messageHistory, prevMsgHistory.current);

  prevSessions.current = useMemo(
    () => extractSessions(prevSessions.current, newEvents, messageHistory),
    [messageHistory, connectionStatus]
  );

  prevMsgHistory.current = messageHistory;
  const lastElement = prevSessions.current[prevSessions.current.length - 1];
  const isArray = Array.isArray(lastElement);
  const lastSessionEnded =
    isArray &&
    lastElement[lastElement.length - 1]?.event_type === "ScheduledSessionEnded";

  return (
    <>
      <DeleteMessageModal />
      <StyledTimelineBox>
        <DateChip
          containerRef={containerRef}
          intersectingRefs={intersectingRefs}
        />
        {prevSessions.current?.map((session, i) => {
          const isLast = i === prevSessions.current.length - 1;
          const prevElement = i !== 0 ? prevSessions.current[i - 1] : null;
          let prevEvent = null;
          if (prevElement) {
            prevEvent = Array.isArray(prevElement)
              ? prevElement[prevElement.length - 1]
              : prevElement;
          }
          return Array.isArray(session) ? (
            <React.Fragment key={session[0].event_guid}>
              <CollapsibleTimeline
                sessionId={sessionId}
                messageHistory={formMessagesInSession({
                  isArray,
                  isLast,
                  session,
                  typingMessage
                })}
                prevEvent={prevEvent}
                lastSession={isLast}
                allParticipants={allParticipants}
                isSessionOwner={isSessionOwner}
                intersectingRefs={intersectingRefs}
                firstTimestampIndex={firstTimestampIndex}
                isSysAdmin={isSysAdmin}
              />
            </React.Fragment>
          ) : (
            renderMessage({
              index: i,
              allParticipants,
              firstTimestampIndex,
              intersectingRefs,
              isSessionOwner,
              event: session,
              sessionId,
              sessions: prevSessions.current,
              isSysAdmin
            })
          );
        })}
        {(lastSessionEnded || !isArray) &&
          typingMessage &&
          renderMessage({
            index: prevSessions.current.length,
            allParticipants,
            firstTimestampIndex,
            intersectingRefs,
            isSessionOwner,
            event: typingMessage,
            sessionId,
            sessions: prevSessions.current,
            isSysAdmin
          })}
        <div ref={messagesBottomRef} />
      </StyledTimelineBox>
    </>
  );
};

export default Timeline;
