import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";

import { Text } from "dark-matter";

import SessionFinishedCard from "./EndOfSessionCard/SessionFinishedCard";
import Styles from "./chat.module.scss";
import loadConfig from "../config/index";

ChatSessionFinished.propTypes = {
  sessionStatus: PropTypes.string,
  otherUser: PropTypes.string
};

ChatSessionFinished.defaultProps = {
  sessionStatus: undefined,
  otherUser: undefined
};

function ChatSessionFinished(props) {
  const { sessionStatus, otherUser } = props;
  const [sessionFinished, setSessionFinished] = useState(false);

  const [config, setConfig] = useState({});
  loadConfig.then((conf) => setConfig(conf));

  useEffect(() => {
    switch (sessionStatus) {
      case "finished_redirect_patient":
      case "finished":
        setSessionFinished(true);
        break;
      case "finished_redirect_therapist":
        window.location.replace(config.clinician_home);
        break;
      default:
        break;
    }
  }, [sessionStatus, config.clinician_home]);

  if (!sessionFinished || !config) {
    return <></>;
  }

  return (
    <>
      <div className={Styles.lastMessage}>
        <Text element="span" size="small">
          {otherUser} left the therapy room
          <span className={Styles.time}>{moment().format("LT")}</span>
        </Text>
      </div>
      <SessionFinishedCard />
    </>
  );
}

export default ChatSessionFinished;
