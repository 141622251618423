import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as CaretDownCircle } from "shared/icons/caret-down-circle.svg";
import styles from "./chat.module.scss";

ScrollButton.propTypes = {
  scrollToBottom: PropTypes.func.isRequired,
  docked: PropTypes.bool.isRequired,
  chatStatus: PropTypes.string.isRequired
};

function ScrollButton({ scrollToBottom, docked, chatStatus }) {
  return (
    <button
      aria-label="Go to most recent message"
      className={docked ? styles.scrollButtonDocked : styles.scrollButton}
      onClick={() => {
        scrollToBottom();
      }}
      type="button"
    >
      {chatStatus}
      <CaretDownCircle className={styles.scrollDownIcon} />
    </button>
  );
}

export default ScrollButton;
