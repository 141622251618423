import { apiGet, apiPost } from "ieso-api-helper";
import { ICredentials, useCredentials } from "hooks/useCredentials.ts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { showToast } from "AtlasChat/components/Toast/Toast.tsx";
import userConsentApiUrls from "./apiUrls.ts";
import { USERS_CONSENT_QUERY_KEY } from "./constants.ts";

const getUserConsentStatus = async (credentials: ICredentials) => {
  const apiUrl = await userConsentApiUrls.getContentStatus();
  return apiGet(apiUrl, { ...credentials });
};

export const getUserConsentStatusApi = () => {
  const credentials = useCredentials();

  return useQuery({
    queryKey: [USERS_CONSENT_QUERY_KEY],
    retry: false,
    enabled: !!credentials.token,
    queryFn: () => getUserConsentStatus(credentials)
  });
};

const updateUserConsentStatus = async (
  policyName: string,
  credentials: ICredentials
) => {
  const apiUrl = await userConsentApiUrls.updateContentStatus(policyName);
  return apiPost(apiUrl, policyName, { ...credentials }).catch((error) => {
    throw error.response;
  });
};

export const updateUserConsentStatusApi = (
  policyName: string,
  handleSuccess: () => void
) => {
  const credentials = useCredentials();
  const queryClient = useQueryClient();
  const mutationFn = () => updateUserConsentStatus(policyName, credentials);

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [USERS_CONSENT_QUERY_KEY]
      });
      handleSuccess();
    },
    onError: () => {
      showToast({
        message: `Unable to acknowledge ${policyName}`,
        appearance: "error"
      });
    }
  });
};
