import React from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Icon, Heading, Text } from "dark-matter";

import useConfig from "../../config/useConfig";

import Styles from "./session-finished-card.module.scss";

const SessionFinishedCard = () => {
  const config = useConfig();
  const { guid: appointmentId } = useParams();
  const transcriptUrl = `${config?.member_home}journey/transcript/${appointmentId}`;

  return (
    <div className={Styles.EOSCard}>
      <Card tackons="mb3 mt5">
        <div className={Styles.EOSCardInner}>
          <div className={Styles.EOSCardIcon}>
            <Icon decorative name="appointment-completed" />
          </div>
          <Heading tackons="pb3">You completed your therapy session</Heading>
          <Text tackons="pb3">
            Remember, you can{" "}
            <a
              className={Styles.EOSCardHintLink}
              href={transcriptUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              re-read your session transcript
            </a>{" "}
            at any time.
          </Text>
          <Text>
            We’re here for you. We want to ensure that you get the most out of
            your time with Ieso. If you have any concerns, please
            <a
              className={Styles.EOSCardHintLink}
              href="https://www.iesohealth.com/en-gb/contact"
              target="_blank"
              rel="noreferrer noopener"
            >
              contact our Patient Services team
            </a>
            who will be happy to help.
          </Text>
          <Button
            tackons="mt2"
            appearance="primary"
            size="small"
            outline
            onClick={() => window.location.replace(config.member_home)}
          >
            Return home
          </Button>
        </div>
      </Card>
    </div>
  );
};

SessionFinishedCard.displayName = "SessionFinishedCard";

export default SessionFinishedCard;
