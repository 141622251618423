import styled from "styled-components";
import { css } from "@styled-system/css";
import { buttonMixin } from "shared/mixins/buttons";
import { Badge, Modal } from "darker-matter";
import { Collapsible } from "Components/Collapsible";
import ActionList from "AtlasChat/components/ActionList/ActionList";
import { Button } from "AtlasChat/components/Button/button";

export const StyledModal = styled(Modal)(
  css({
    "> div > header ~ div": {
      minHeight: "80px"
    }
  })
);

export const StyledBadge = styled(Badge)(
  css({
    width: "100%",
    borderRadius: 0,
    p: 3
  })
);

export const StyledCollapsible = styled(Collapsible)(
  css({
    width: "100%",
    "&:focus-within div": {
      boxShadow: "none"
    }
  })
);

export const DownloadTranscriptButton = styled(Button)`
  svg {
    display: none;
  }
  div {
    margin-left: 0;
  }
  ${buttonMixin("neutral")}

  @media screen and (max-width: 640px) {
    svg {
      display: block;
    }
    div {
      display: none;
    }
  }
`;

export const DownloadList = styled(ActionList)`
  max-height: 40vh;
  overflow-y: auto;
`;

export const JoinBtn = styled(Button)(buttonMixin("extended1"));
