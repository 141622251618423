import { connect } from "react-redux";

import { setReferralID } from "store/chatendpoints/actions";
import {
  getSessionStatus,
  isValidSession as getIsValidSession,
  users,
  getSessionAvailability,
  getAddingParticipantState
} from "store/chatconnection/selectors";
import { getMyId } from "Identity/selectors";
import { IParticipant, SessionAvailability } from "AtlasChat/types.ts";
import Chat from "./Chat.tsx";

function mapStateToProps(state) {
  const myId: string = getMyId(state);
  const sessionStatus: string = getSessionStatus(state);
  const invalidSession: boolean = getIsValidSession(state);
  const participants: Record<string, IParticipant> = users(state);
  const sessionAvailability: SessionAvailability = getSessionAvailability(
    state
  );
  const addingParticipantState: {
    loading: boolean;
    error: unknown;
  } = getAddingParticipantState(state);
  const hasTherapistJoined = Object.values(participants).some(
    (user) => user.is_owner
  );

  return {
    sessionStatus,
    invalidSession,
    sessionAvailability,
    addingParticipantState,
    myId,
    hasTherapistJoined
  };
}

const mapDispatchToProps = (dispatch) => ({
  setReferralID: (referralId) => dispatch(setReferralID(referralId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
