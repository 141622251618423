import { connect } from "react-redux";

import {
  SET_REFERENCE,
  SCROLL_TO_BOTTOM,
  SET_MEASURER_CACHE,
  CLEAR_CACHE,
  SET_DOCKED
} from "Chat/scrollingMiddleware";
import {
  getMessageHistoryInOrder,
  getMessageHistoryLength,
  getMessageHistoryKeyMap,
  getConnectionStatus,
  shouldShowConnectionStatus as shouldShowConnectionStatusImported,
  shouldShowReconnectButton,
  isReconnected as isReconnectedImported,
  getSessionStatus
} from "store/chatconnection/selectors";
import {
  getChatStatus,
  getDocked,
  getHasReceivedMessageHistory
} from "Chat/data/selectors";
import { getUser, getMyId } from "Identity/selectors";
import { USER_RECONNECT } from "../store/chatconnection/types";
import ChatWindow from "./ChatWindow";
import chatReducer from "./reducer";

const getOtherParticipants = (participants, myID) => {
  const otherUsersID = Object.keys(participants).filter((p) => p !== myID);
  return participants[otherUsersID] && participants[otherUsersID].fullname;
};

function mapStateToProps(state, ownProps) {
  const { participants } = state.chatendpoints;
  const user = getUser(state);

  const docked = getDocked(state);
  const chatStatus = getChatStatus(state);
  const hasReceivedMessageHistory = getHasReceivedMessageHistory(state);
  const messageHistory = getMessageHistoryInOrder(state, ownProps);

  const userId = getMyId(state);
  const myParticipantData = participants[userId];
  const otherUser = getOtherParticipants(
    state.chatconnection.participants,
    userId
  );

  const messagesLength = getMessageHistoryLength(state, ownProps);
  const connectionStatus = getConnectionStatus(state, ownProps);

  const shouldShowConnectionStatus = shouldShowConnectionStatusImported(state);
  const showReconnectButton = shouldShowReconnectButton(state);
  const isReconnected = isReconnectedImported(state);

  const messageHistoryKeyMap = getMessageHistoryKeyMap(state);

  const sessionStatus = getSessionStatus(state);

  return {
    ...ownProps,
    user,
    userId,
    participants,
    myParticipantData,
    messagesLength,
    docked,
    chatStatus,
    hasReceivedMessageHistory,
    messageHistoryKeyMap,
    messageHistory,
    connectionStatus,
    shouldShowConnectionStatus,
    showReconnectButton,
    isReconnected,
    sessionStatus,
    otherUser
  };
}

const mapDispatchToProps = (dispatch) => ({
  setMeasurerCache: (cache) =>
    dispatch({
      type: SET_MEASURER_CACHE,
      cache
    }),
  clearCache: () =>
    dispatch({
      type: CLEAR_CACHE
    }),
  clearCacheIndex: (index) =>
    dispatch({
      type: CLEAR_CACHE,
      index
    }),
  registerScroll: (ref) =>
    dispatch({
      type: SET_REFERENCE,
      handle: "chat_window",
      ref
    }),
  scrollToBottom: () =>
    dispatch({
      type: SCROLL_TO_BOTTOM,
      handle: "chat_window"
    }),
  setDocked: (value) =>
    dispatch({
      type: SET_DOCKED,
      value
    }),
  onReconnectClick: () => dispatch({ type: USER_RECONNECT })
});

export { createScrollingMiddleware } from "./scrollingMiddleware";

export { chatReducer };

export default connect(mapStateToProps, mapDispatchToProps)(ChatWindow);
