import React from "react";
import moment from "moment";
import parser from "html-react-parser";

import Avatar from "Avatar/Avatar";
import Spinner from "Spinner/Spinner";
import { ReactComponent as AlertIcon } from "shared/icons/alert-circle.svg";
import styles from "./ChatHistoryRow.module.scss";

const chatMessage = (item) => {
  let messageStyle = "";

  if (item.is_me) {
    switch (item.transit_status) {
      case "sending":
      case "failed": {
        messageStyle = styles.myMessageSending;
        break;
      }
      default: {
        messageStyle = styles.myMessage;
        break;
      }
    }
  } else {
    messageStyle = styles.message;
  }

  const transitStatusMap = {
    sending: (
      <div className={styles.sending}>
        <Spinner className={styles.sendingSpinner} />
        Sending &hellip;
      </div>
    ),
    failed: <div className={styles.failed}>Failed to send</div>
  };

  return (
    <div
      className={item.is_me ? styles.innerRight : styles.inner}
      data-testid="transcript-message-container"
    >
      {item.first_in_group ? (
        <div className={styles.avatar}>
          <Avatar name={item.name} isMe={item.is_me} />
        </div>
      ) : null}
      {item.first_in_group ? (
        <div className={styles.meta}>
          <span
            tabIndex={-1}
            id={`author-${item.index}`}
            className={styles.author}
          >
            {item.name}
          </span>
          <span tabIndex={-1} className={styles.time}>
            {moment(item.time_utc).format("LT")}
          </span>
        </div>
      ) : null}
      <div
        tabIndex={-1}
        className={`${messageStyle} ${
          item.last_in_group ? styles.lastMessage : ""
        }`}
      >
        {item.transit_status === "failed" ? (
          <AlertIcon className={styles.failedIcon} />
        ) : null}
        <div className={styles.messageText}>
          {parser(item.sanitizedContent)}
        </div>
      </div>

      {transitStatusMap[item.transit_status] || null}
    </div>
  );
};

const userTyping = (item) => (
  <div className={styles.inner}>
    {item.first_in_group ? (
      <div className={styles.avatar}>
        <Avatar name={item.name} isMe={item.is_me} />
      </div>
    ) : null}
    {item.first_in_group ? (
      <div className={styles.meta}>
        <span
          tabIndex={-1}
          id={`author-${item.index}`}
          className={styles.author}
        >
          {item.name} is typing
        </span>
      </div>
    ) : null}
    <div
      data-testid="typing-notification"
      tabIndex={-1}
      className={`
              ${styles.messageIncoming}
              ${styles.lastMessage}
            `}
    >
      <div className={styles.messageText}>
        <div className={styles.isTyping} />
      </div>
    </div>
  </div>
);

const ChatHistoryRow = (props) => {
  const { item } = props;

  if (item.event_type === "typing") {
    return userTyping(item);
  }
  return chatMessage(item);
};

export default ChatHistoryRow;
