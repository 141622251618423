/* eslint-disable camelcase */
import axios from "axios";

import { FINISH_SESSION_THERAPIST_REDIRECT } from "store/chatconnection/types";
import { getBearerAuthHeader } from "Identity/selectors";
import { getAppointmentId } from "store/chatendpoints/selectors";
import loadConfig from "../config/index";

let config;
const configRequest = loadConfig.then((c) => {
  config = c;
});

const types = {
  ENDING_APPOINTMENT: "End Appointment / ENDING_APPOINTMENT",
  CANCEL_ENDING_APPOINTMENT: "End Appointment / CANCEL_ENDING_APPOINTMENT",
  END_APPOINTMENT: "End Appointment / END_APPOINTMENT",
  SET_STATUS: "End Appointment / SET_STATUS",
  SET_TECH_QUALITY: "End Appointment / SET_TECH_QUALITY",
  SET_SERVICE_EXPERIENCE: "End Appointment / SET_SERVICE_EXPERIENCE"
};

const endingAppointment = () => ({
  type: types.ENDING_APPOINTMENT
});

const cancelEndingAppointment = () => ({
  type: types.CANCEL_ENDING_APPOINTMENT
});

const endAppointment = (status) => ({
  type: types.END_APPOINTMENT,
  status
});

const setStatus = (status) => ({
  type: types.SET_STATUS,
  status
});

const setTechQuality = (value) => ({
  type: types.SET_TECH_QUALITY,
  value
});

const setOverallExperience = (value) => ({
  type: types.SET_SERVICE_EXPERIENCE,
  value
});

const submitSessionFeedback = () => async (dispatch, getState) => {
  const state = getState();
  await configRequest;
  const axiosConfig = {
    method: "post",
    url: `${config.tss_url}/therapy/v1/sessions/${getAppointmentId(
      getState()
    )}/feedback`,
    headers: {
      Authorization: getBearerAuthHeader(getState())
    },
    crossDomain: true,
    data: {
      rating: state.UI.tech_quality,
      experience: state.UI.overall_service_experience
    }
  };

  return axios(axiosConfig)
    .then(() =>
      dispatch({
        type: FINISH_SESSION_THERAPIST_REDIRECT
      })
    )
    .catch((error) => {
      state.trackError(error);
      // console.error("error", "getAppointmentInfo()", getState(), error);
    });
};

// Middleware for EndAppointment (side effect is call to the api when submitting feedback, also ending a session)
export function EndAppointmentMiddleware() {}

const init = {
  endingAppointment: false
};

export function FeedbackUI(state = init, action) {
  switch (action.type) {
    case types.ENDING_APPOINTMENT:
      return { ...state, endingAppointment: true };
    case types.CANCEL_ENDING_APPOINTMENT:
      return { ...state, endingAppointment: false };
    default:
      return state;
  }
}

export function FeedbackStatus(state = "", action = {}) {
  switch (action.type) {
    case types.SET_STATUS:
      if (action.status) {
        return action.status;
      }
      break;
    default:
  }
  return state;
}
export function tech_quality(state = "", action = {}) {
  switch (action.type) {
    case types.SET_TECH_QUALITY:
      if (action.value) {
        return action.value;
      }
      break;
    default:
  }
  return state;
}

export function overall_service_experience(state = "", action = {}) {
  switch (action.type) {
    case types.SET_SERVICE_EXPERIENCE:
      if (action.value) {
        return action.value;
      }
      break;
    default:
  }
  return state;
}

const actions = {
  endingAppointment,
  cancelEndingAppointment,
  endAppointment,
  setStatus,
  setTechQuality,
  setOverallExperience,
  submitSessionFeedback
};

export { types, actions };
