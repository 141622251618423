import React from "react";
import "./VideoSessionButton.css";

export function CheckingVideoAnim() {
  return (
    <svg width="180px" height="10px" className="videoButtonSvg" version="1.1">
      <path
        fill="transparent"
        stroke="#0d93d1"
        strokeWidth="10"
        d="M -20 5 L 200 5"
        className="path"
      />
      <path
        fill="transparent"
        stroke="white"
        strokeWidth="15"
        d="M -20 5 L 200 5"
        className="line2"
      />
      <path
        fill="transparent"
        stroke="#0d93d1"
        strokeWidth="10"
        d="M -20 5 L 200 5"
        className="line1"
      />
    </svg>
  );
}
