/* eslint-disable camelcase */
import { combineReducers } from "redux";

import { CHAT_SET_MESSAGE_HISTORY } from "store/chatconnection/types";
import {
  TOGGLE_ENTER_TO_SEND,
  SET_DOCKED,
  SET_CHAT_STATUS_MESSAGE
} from "./scrollingMiddleware";

function enterToSend(state = true, action = {}) {
  switch (action.type) {
    case TOGGLE_ENTER_TO_SEND:
      return !state;
    default:
      return state;
  }
}

function docked(state = true, action = {}) {
  switch (action.type) {
    case SET_DOCKED:
      if (action.value !== undefined) return action.value;
      break;
    default:
  }
  return state;
}

function has_received_message_history(state = false, action = {}) {
  switch (action.type) {
    case CHAT_SET_MESSAGE_HISTORY:
      return true;
    default:
  }
  return state;
}

function chat_status(state = "Scroll to bottom", action = {}) {
  switch (action.type) {
    case SET_DOCKED:
      if (action.value === false) return "Scroll to bottom";
      break;
    case SET_CHAT_STATUS_MESSAGE:
      if (action.value !== undefined) return action.value;
      break;
    default:
  }
  return state;
}

export default combineReducers({
  enterToSend,
  docked,
  chat_status,
  has_received_message_history
});
