import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { ReactComponent as SpeechBubble } from "shared/icons/speech-bubble.svg";
import { getLoading } from "Loading/data/selectors";
import styles from "./loading.module.scss";

Loading.propTypes = {
  className: PropTypes.string,
  fullscreen: PropTypes.bool,
  message: PropTypes.string.isRequired,
  loading: PropTypes.bool
};

Loading.defaultProps = {
  className: undefined,
  fullscreen: false,
  loading: true
};

function mapStateToProps(state) {
  const loading = getLoading(state);
  return {
    loading
  };
}

function Loading({ fullscreen, className, message, loading }) {
  if (!loading) return null;
  return (
    <div
      className={`${fullscreen ? styles.fullscreen : ""} ${className || ""}`}
    >
      <div className={styles.animation}>
        <SpeechBubble className={styles.speechBubble} />
        <div className={styles.animationDots} />
      </div>
      <div className={styles.message}>{message}</div>
    </div>
  );
}

export default connect(mapStateToProps)(Loading);
