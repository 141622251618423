import { connect } from "react-redux";

import { setAppointment as setAppointmentAction } from "store/chatendpoints/actions";
import { getAppointmentId } from "store/chatendpoints/selectors";
import {
  getSessionStatus,
  isValidSession
} from "store/chatconnection/selectors";
import { APP_UNLOADING } from "store/global/types";
import { getMyId } from "Identity/selectors";
import Appointment from "./Appointment";

function mapStateToProps(state) {
  const appointmentId = getAppointmentId(state);
  const myId = getMyId(state);
  const sessionStatus = getSessionStatus(state);
  const sessionNotFound = isValidSession(state);

  return {
    appointmentId,
    sessionStatus,
    sessionNotFound,
    myId
  };
}

const mapDispatchToProps = (dispatch) => ({
  setAppointment: (appointmentId) =>
    dispatch(setAppointmentAction(appointmentId)),
  appUnloading: () => {
    dispatch({ type: APP_UNLOADING });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
