import { SIGNAL_R_RECEIVE_EVENT } from "../store/chatconnection/types";

export const SET_REFERENCE = "chathistory/SET_REFERENCE";
export const SCROLL_TO_BOTTOM = "chathistory/SCROLL_TO_BOTTOM";
export const TOGGLE_ENTER_TO_SEND = "chatUI/TOGGLE_ENTER_TO_SEND";
export const SET_MEASURER_CACHE = "chatUI/SET_MEASURER_CACHE";
export const CLEAR_CACHE = "chatUI/CLEAR_CACHE";
export const SET_DOCKED = "chatUI/SET_DOCKED";
export const SET_CHAT_STATUS_MESSAGE = "chatUI/SET_CHAT_STATUS_MESSAGE";

// selectors
export const getEnterToSend = (state) => state.UI.chat.enterToSend;

// action creators.

export function toggleEnterToSend() {
  return (dispatch, getState) => {
    const currentEnterToSend = getEnterToSend(getState());
    dispatch({ type: TOGGLE_ENTER_TO_SEND, value: !currentEnterToSend });
  };
}

// middleware for Chat UI
export function createScrollingMiddleware() {
  return ({ dispatch }) => {
    const references = {};
    return (next) => (action) => {
      switch (action.type) {
        case SET_REFERENCE: {
          if (action.handle && action.ref) {
            references[action.handle] = action.ref;
          }
          return undefined;
        }
        case SCROLL_TO_BOTTOM: {
          if (action.handle && references[action.handle]) {
            references[action.handle].scrollToBottom();
          }
          break;
        }
        case SIGNAL_R_RECEIVE_EVENT: {
          dispatch({ type: SET_CHAT_STATUS_MESSAGE, value: "New message" });
          break;
        }
        default:
        // Do Nothing
      }
      return next(action);
    };
  };
}
