/* eslint-disable camelcase */
import { connect } from "react-redux";

import { actions } from "AppointmentFeedback";

import {
  getOtherUserOnlineStatus,
  getSessionStatus
} from "store/chatconnection/selectors";
import { getMyId } from "Identity/selectors";
import AppointmentHeader from "./AppointmentHeader";

const getOtherParticipants = (participants, myID) => {
  const otherUsersID = Object.keys(participants).filter((p) => p !== myID);
  return participants[otherUsersID] && participants[otherUsersID].fullname;
};

const isSessionEnding = (sessionStatus) =>
  sessionStatus ===
  ("finished_redirect_patient" || "finished_redirect_therapist");

const mapStateToProps = (state) => {
  let myID = "";
  let name = "";

  myID = getMyId(state);
  name = getOtherParticipants(state.chatconnection.participants, myID);

  const sessionStatus = getSessionStatus(state);
  const isOnline =
    getOtherUserOnlineStatus(state) && !isSessionEnding(sessionStatus);

  return {
    name,
    isOnline
  };
};

const mapDispatchToProps = (dispatch) => ({
  endingAppointment: () => dispatch(actions.endingAppointment())
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentHeader);
