/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-arrow-callback */
import React, { forwardRef, useMemo, useRef } from "react";
import { IMessage } from "AtlasChat/types";
import usePopover from "AtlasChat/components/Popover/Popover.tsx";
import { useArrowNavigation } from "AtlasChat/hooks/useArrowNavigation.ts";
import { getSessionDates, ISessionDates } from "../helpers.tsx";
import { DownloadList, DownloadTranscriptButton } from "./styles.ts";

interface IDownloadTranscriptProps {
  messages: IMessage[];
  onSelect: (session: ISessionDates | ISessionDates[]) => void;
}

export const DownloadTranscript = forwardRef<
  HTMLButtonElement,
  IDownloadTranscriptProps
>(function DownloadTranscript({ messages, onSelect }, btnRef) {
  const ref = useRef<HTMLDivElement>(null);
  const { Popover, hide, isShow } = usePopover("download-transcript");
  const { handleKeyDown } = useArrowNavigation({
    isShow,
    hide,
    ref
  });
  const sessions = useMemo(() => getSessionDates(messages), [messages]);
  const items = sessions.reduce<Array<{ label: string; onClick: () => void }>>(
    (acc, session) => {
      if (session.end) {
        acc.push({
          label: session.label,
          onClick: () => {
            onSelect(session);
          }
        });
      }
      return acc;
    },
    []
  );

  const userSections = [
    {
      items: [
        ...items,
        {
          label: "Download all",
          onClick: () => {
            onSelect(sessions);
          }
        }
      ]
    }
  ];

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, @typescript-eslint/no-explicit-any
    <div id="download-transcript" onKeyDown={handleKeyDown as any}>
      <Popover
        trigger={
          <DownloadTranscriptButton
            magnitude="small"
            icon="download"
            ariaLabel="Download transcript"
            ariaDescription="Download your transcript"
            forwardedRef={btnRef}
          >
            Download transcript
          </DownloadTranscriptButton>
        }
        offset={[0, 16]}
        ariaLabel="Download transcript"
        id="download-transcript-menu"
      >
        <div ref={ref}>
          <DownloadList sections={userSections} onItemClick={hide} />
        </div>
      </Popover>
    </div>
  );
});

export default DownloadTranscript;
