/* eslint-disable @typescript-eslint/no-use-before-define */
import { useQuery } from "@tanstack/react-query";
import { apiGet } from "ieso-api-helper";
import { useMemo } from "react";
import sanitizeMessage from "utils/sanitize-message";
import { IMessage } from "AtlasChat/types.ts";
import { ICredentials, useCredentials } from "hooks/useCredentials.ts";
import timelineApiUrls from "./apiUrls.ts";
import { TIMELINE_REQUESTS_STALE_TIME } from "./constants.ts";

export const useGetEventsApi = (
  sessionId: string
): {
  status: "loading" | "error" | "success";
  data: IMessage[];
  error: unknown;
  isLoading: boolean;
} => {
  const credentials = useCredentials();

  const { isLoading, error, status, data } = useQuery({
    queryKey: ["get-session-events", sessionId],
    enabled: !!sessionId && !!credentials.token,
    staleTime: TIMELINE_REQUESTS_STALE_TIME,
    refetchOnWindowFocus: true,
    queryFn: () => getEvents(sessionId, credentials)
  });

  return useMemo(
    () => ({
      status,
      data,
      error,
      isLoading
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, data]
  );
};

const visibleEventTypes = [
  "Sentence",
  "ScheduledSessionStarted",
  "ScheduledSessionEnded"
];

export const getEvents = async (
  sessionId: string,
  credentials: ICredentials
) => {
  const apiUrl = await timelineApiUrls.getEvents(sessionId);

  return apiGet<IMessage[]>(apiUrl, {
    ...credentials,
    responseTransformation: (response) =>
      response
        .filter((e) => visibleEventTypes.includes(e.event_type))
        .map((m) => ({ ...m, sanitizedContent: sanitizeMessage(m.content) }))
  });
};
