import React from "react";
import PropTypes from "prop-types";

import { CONNECTION_STATUS_STRINGS } from "store/chatconnection/consts";
import styles from "./ConnectionStatus.module.scss";

export const statusMessage = {
  Disconnected:
    "Your computer seems to be offline at the moment. We'll try to connect you automatically, or you can",
  Reconnected: "Reconnected"
};

ConnectionStatus.propTypes = {
  isReconnected: PropTypes.bool,
  onReconnectClick: PropTypes.func.isRequired,
  shouldShow: PropTypes.bool,
  showReconnectButton: PropTypes.bool,
  statusString: PropTypes.string
};

ConnectionStatus.defaultProps = {
  isReconnected: false,
  shouldShow: false,
  showReconnectButton: false,
  statusString: ""
};

function ConnectionStatus({
  isReconnected,
  onReconnectClick,
  shouldShow,
  showReconnectButton,
  statusString
}) {
  if (shouldShow) {
    return (
      <div className={`${styles.overlayBanner} js-connection-status`}>
        <span>{getStatusMessage()}</span>
        {showReconnectButton && (
          <button
            className={styles.button}
            onClick={onReconnectClick}
            type="button"
          >
            try now
          </button>
        )}
      </div>
    );
  }
  return null;

  function getStatusMessage() {
    if (isReconnected) return statusMessage.Reconnected;
    if (statusString === CONNECTION_STATUS_STRINGS.Disconnected)
      return statusMessage.Disconnected;
    return statusString;
  }
}

export default ConnectionStatus;
